import { LexicalNode, TextNode, SerializedTextNode, Spread, EditorConfig } from 'lexical';

export type SerializedReferenceCitationNode = Spread<{ referenceId: string }, SerializedTextNode>;

export class ReferenceCitationNode extends TextNode {
  __referenceId: string;

  constructor(referenceId: string, key?: string) {
    super(referenceId, key);

    this.__referenceId = referenceId;
  }

  static getType(): string {
    return 'reference-citation';
  }

  static clone(node: ReferenceCitationNode): ReferenceCitationNode {
    return new ReferenceCitationNode(node.__referenceId, node.__key);
  }

  createDOM(): HTMLElement {
    const element = document.createElement('sup');
    element.style.cursor = 'pointer';

    element.textContent = this.getTextContent();

    return element;
  }

  updateDOM(prevNode: this, dom: HTMLElement, config: EditorConfig): boolean {
    return super.updateDOM(prevNode, dom, config);
  }

  static importJSON(
    serializedNode: SerializedReferenceCitationNode,
  ): ReferenceCitationNode {
    return new ReferenceCitationNode(serializedNode.referenceId);
  }

  exportJSON(): SerializedReferenceCitationNode {
    return {
      ...super.exportJSON(),
      referenceId: this.__referenceId,
      type: ReferenceCitationNode.getType(),
    };
  }
}

export function $createReferenceCitationNode(referenceId: string): ReferenceCitationNode {
  return new ReferenceCitationNode(referenceId);
}

export function $isReferenceCitationNode(
  node: LexicalNode | null | undefined,
): node is ReferenceCitationNode {
  return node instanceof ReferenceCitationNode;
}
