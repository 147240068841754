import _ from 'lodash';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { batchActions } from 'redux-batched-actions';
import * as documentsActions from 'containers/Documents/actions';
import { useEditorToggle } from 'containers/EditorToggle/useEditorToggle';
import * as relationsActions from 'containers/Relations/actions';
import { getActiveLayer } from 'hooks/useActiveLayer';
import * as Models from 'models';
import { stylesComparator } from 'utils/styles/stylesComparator';
import { EditorHook } from '../editor';
import { ctaStylesToSource } from '../utils/styles';
import { SizeResetHook } from './useSizeReset';
import { StylesHook } from './useStyles';

type SaveHookProps = {
  relation: Models.LayeredRegularRelationMap<Models.CallToActionStyles>;
  currentCountry: Immutable.List<string>;
  currentLanguage: Immutable.List<string>;
  isAutoFitContent: boolean;
  editMode: boolean;
  document: Models.CallToActionMap;
  cellHeight: number;
  cellWidth: number;
  layoutRelations: Models.LayeredRelationsMap;
};

export const useSave = (
  props: SaveHookProps,
  stylesHook: StylesHook,
  sizeResetHook: SizeResetHook,
  editorHook: EditorHook,
  link: string,
): void => {
  const {
    relation,
    document,
    currentCountry,
    currentLanguage,
    isAutoFitContent,
    cellHeight,
    cellWidth,
    layoutRelations,
    editMode,
  } = props;

  const isMounting = useRef(true);
  const activeLayer = getActiveLayer();
  const { isLexicalMode } = useEditorToggle();
  const { styles } = stylesHook;
  const { cellHeightBeforeEdit, cellWidthBeforeEdit } = sizeResetHook;

  const dispatch = useDispatch();

  const saveDocument = (): void => {
    const updatedRelation = relation.updateIn(['styles', activeLayer], values => values.withMutations(
      value => ctaStylesToSource(styles, value)
        .set('isAutoFitContent', isAutoFitContent),
    ));
    const { title, rawContent, text } = editorHook.getData();
    const contentPropName: string = isLexicalMode ? 'lexicalState' : 'rawContent';

    const updatedDocument = document.withMutations(
      doc => doc
        .set('name', isLexicalMode ? document.get('name') : title) // IN-PROGRESS: title should be used after switching to lexical state
        .set('text', text)
        .set(contentPropName, rawContent)
        .set('link', link.trim())
        .set('isMockDocument', false)
        // https://issues.merck.com/browse/DCC-4748
        .set('language', currentLanguage)
        .set('country', currentCountry),
    );
    const actions: Models.IAction[] = [];

    const needsToUpdateLayeredRelations = cellWidthBeforeEdit !== cellWidth ||
      cellHeightBeforeEdit !== cellHeight ||
      !_.isEqualWith(updatedRelation.getIn(['styles', activeLayer]).toJS(),
        relation.getIn(['styles', activeLayer]).toJS(), stylesComparator);

    if (needsToUpdateLayeredRelations) {
      actions.push(relationsActions.updateLayeredRelations(layoutRelations.set(updatedRelation.get('id'), updatedRelation)));
    }

    const needsToUpdateDocument = updatedDocument.get('link') !== document.get('link') ||
      updatedDocument.get('name') !== document.get('name') ||
      updatedDocument.get(contentPropName) !== document.get(contentPropName as keyof Models.CallToAction);

    if (needsToUpdateDocument) {
      actions.push(documentsActions.setDocument(updatedDocument));
    }

    if (actions.length !== 0) {
      dispatch(batchActions(actions));
    }
  };

  useEffect(() => {
    if (isMounting.current) {
      isMounting.current = false;

      return;
    }
    if (editMode) {
      editorHook.returnFocusToEditor();

      return;
    }

    saveDocument();
  }, [editMode]);
};
