import { HistoryStateEntry } from '@lexical/history';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { mergeRegister } from '@lexical/utils';
import { COMMAND_PRIORITY_HIGH, REDO_COMMAND, UNDO_COMMAND } from 'lexical';
import { cloneDeep, isEqual, omit } from 'lodash';
import React, { useEffect, useMemo } from 'react';

// internal tags of lexical history plugin
export enum HISTORY_TAGS {
  HISTORIC = 'historic',
  MERGE = 'history-merge',
}

function isEqualWithoutProperties<T extends object>(
  a: T,
  b: T,
  propertiesToSkip: string[],
): boolean {
  return isEqual(omit(a, ...propertiesToSkip), omit(b, ...propertiesToSkip));
}

type HistoryEntry<T> = HistoryStateEntry & { extra: T };

export type HistoryWithExtraState<T> = {
  current: null | HistoryEntry<T>;
  redoStack: HistoryEntry<T>[];
  undoStack: HistoryEntry<T>[];
};

type Props<T> = {
  readonly extraState: T;
  readonly extraStateSetter: (val: T) => void;
  readonly mergeExtraStateKeys?: string[];
};

export function HistoryWithExtraStatePlugin<T extends object>(props: Props<T>): JSX.Element {
  const {
    extraState,
    extraStateSetter,
    mergeExtraStateKeys,
  } = props;

  const [editor] = useLexicalComposerContext();

  const historyState: HistoryWithExtraState<T> = useMemo(() => ({
    current: null,
    redoStack: [],
    undoStack: [],
  }), []);

  const cache = useMemo(() => ({
    extraState: cloneDeep(extraState),
    extraStateSetter,
  }), []);
  cache.extraStateSetter = extraStateSetter;

  useEffect(() => mergeRegister(
    editor.registerUpdateListener(({ tags }) => {
      const { current } = historyState;
      if (tags.has(HISTORY_TAGS.HISTORIC)) {
        return;
      }
      if (current && !current.extra) {
        current.extra = cache.extraState;
      }
    }),
    editor.registerCommand(
      UNDO_COMMAND,
      () => {
        const { undoStack, current } = historyState;
        const { extra } = undoStack.at(-1) ?? {};
        if (extra && !isEqual(extra, current?.extra)) {
          cache.extraState = extra;
          cache.extraStateSetter(extra);
        }

        return false;
      },
      COMMAND_PRIORITY_HIGH,
    ),
    editor.registerCommand(
      REDO_COMMAND,
      () => {
        const { redoStack, current } = historyState;
        const { extra } = redoStack.at(-1) ?? {};

        if (extra && !isEqual(extra, current?.extra)) {
          cache.extraState = extra;
          cache.extraStateSetter(extra);
        }

        return false;
      },
      COMMAND_PRIORITY_HIGH,
    ),
  ), [editor, historyState]);

  useEffect(() => {
    if (isEqual(cache.extraState, extraState)) {
      return;
    }
    cache.extraState = extraState;
    if (mergeExtraStateKeys?.length && isEqualWithoutProperties(cache.extraState, extraState, mergeExtraStateKeys)) {
      return;
    }
    const currentState = editor.getEditorState().clone();
    editor.setEditorState(currentState, { tag: 'extra-history' });
  }, [extraState]);

  return <HistoryPlugin externalHistoryState={historyState} />;
}
