import classNames from 'classnames';
import { CallToActionEditor } from 'modules/Lexical/components/CallToActionEditor';
import React from 'react';
import { useLexicalCTAEditorContext } from '../context/LexicalCTAEditor';

const LexicalButton = (): JSX.Element => {
  const {
    lexicalEditorRef,
    lexicalHookOnChange,
    editMode,
    editorWrap,
    editorState, //IN-PROGRESS: should be draftContent from backend
    lexicalState,
    styles,
    cssButton,
    brandProps,
    returnFocusToEditor,
    history,
  } = useLexicalCTAEditorContext();

  return (
    <button
      onClick={returnFocusToEditor}
      onDoubleClick={returnFocusToEditor}
      className={classNames('reset-button', cssButton)}
      style={styles}
    >
      <div ref={editorWrap}>
        <CallToActionEditor
          ref={lexicalEditorRef}
          draftContent={editorState.getCurrentContent()}
          lexicalState={lexicalState}
          onChange={lexicalHookOnChange}
          editMode={editMode}
          brandProps={brandProps}
          brandStyle={undefined} //IN-PROGRESS
          historyExtraState={history.extraState}
          historyExtraStateSetter={history.extraStateSetter}
        />
      </div>
    </button>
  );
};

export default LexicalButton;
