import { ListItemNode, SerializedListItemNode } from '@lexical/list';
import {
  EditorConfig,
  LexicalNode,
  NodeKey,
} from 'lexical';

/**
* Custom List item node with dynamic line-height functionality
*/
export class CustomListItemNode extends ListItemNode {
  __lineHeight: number | undefined;

  constructor(value?: number, checked?: boolean, key?: NodeKey, lineHeight?: number) {
    super(value, checked, key);
    this.__lineHeight = lineHeight;
  }

  static getType(): string {
    return 'custom-list-item';
  }

  static clone(node: CustomListItemNode): CustomListItemNode {
    return new CustomListItemNode(node.__value, node.__checked, node.__key, node.__lineHeight);
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = super.createDOM(config);
    if (this.__lineHeight) {
      element.style.lineHeight = this.__lineHeight.toString();
    }

    return element;
  }

  updateDOM(prevNode: CustomListItemNode, dom: HTMLElement, config: EditorConfig): boolean {
    const isUpdated = super.updateDOM(prevNode, dom, config);

    const prevLineHeight = prevNode.__lineHeight;
    const nextLineHeight = this.__lineHeight;

    if (prevLineHeight !== nextLineHeight) {
      dom.style.lineHeight = nextLineHeight ? nextLineHeight.toString() : '';
    }

    return isUpdated;
  }

  static importJSON(
    serializedNode: SerializedListItemNode & { lineHeight?: number },
  ): CustomListItemNode {
    const node = super.importJSON(serializedNode) as CustomListItemNode;
    if (serializedNode.lineHeight) {
      node.setLineHeight(serializedNode.lineHeight);
    }

    return node;
  }

  exportJSON(): SerializedListItemNode & { lineHeight?: number } {
    const json = super.exportJSON();

    return {
      ...json,
      type: this.getType(),
      lineHeight: this.__lineHeight ?? undefined,
    };
  }

  setLineHeight(lineHeight: number): void {
    this.getWritable().__lineHeight = lineHeight;
  }

  getLineHeight(): number | undefined {
    return this.getLatest().__lineHeight;
  }
}

export function $isCustomListItemNode(
  node: LexicalNode | null | undefined,
): node is CustomListItemNode {
  return node instanceof CustomListItemNode;
}

export function $createCustomListItemNode(checked?: boolean): CustomListItemNode {
  return new CustomListItemNode(undefined, checked);
}
