import { $isListNode, ListNode } from '@lexical/list';
import { InitialEditorStateType, LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { List } from 'immutable';
import { $getRoot, LexicalEditor, TextNode } from 'lexical';
import React from 'react';
import { LEXICAL_TEXT_NODES } from 'components/ArtboardAssets/Text/components/LexicalTextEditor';
import { useLexicalEditorState } from '../hooks/useLexicalEditorState';
import { ColourlessTextNode } from '../nodes/ColourlessTextNode';
import { $createCustomParagraphNode } from '../nodes/CustomParagraphNode';
import { mainTheme } from '../Theme/mainTheme';

const $removeListAndInsertParagraphs = (listNode: ListNode): void => {

  listNode.getAllTextNodes().forEach((node) => {
    const paragraphNode = $createCustomParagraphNode();
    paragraphNode.append(node);
    listNode.insertBefore(paragraphNode);
  });

  listNode.remove();
};

const initiateEditorStateWithoutList = (editorStateStr: string | null): InitialEditorStateType => {
  if (!editorStateStr) {
    return editorStateStr;
  }

  return (editor: LexicalEditor) => {
    const editorState = editor.parseEditorState(editorStateStr);

    editor.update(() => {
      const root = $getRoot();
      const children = root.getChildren();
      children.filter($isListNode).forEach($removeListAndInsertParagraphs);
    });

    editor.setEditorState(editorState);
  };
};

const editorConfig = {
  namespace: 'text-preview-editor',
  editable: false,
  theme: mainTheme,
  onError: (error: Error): void => {
    throw error;
  },
  nodes: [
    ...LEXICAL_TEXT_NODES,
    ColourlessTextNode,
    {
      replace: TextNode,
      with: (node: TextNode): ColourlessTextNode => new ColourlessTextNode(node.__text),
    },
  ],
};

type Props = {
  draftjsContent?: Draft.ContentState;
  lexicalState?: string;
};

export const TextPreviewEditor = (props: Props): JSX.Element => {
  const { draftjsContent, lexicalState } = props;

  const editorStateStr = useLexicalEditorState({
    editor: null,
    draftjsContent,
    brandProps: {
      colors: List([]),
      fonts: List([]),
    },
    lexicalStateStringified: lexicalState,
  });

  return (
    <LexicalComposer initialConfig={{
      ...editorConfig,
      editorState: initiateEditorStateWithoutList(editorStateStr),
    }} >
      <RichTextPlugin
        contentEditable={<ContentEditable className="editor-input" />}
        ErrorBoundary={LexicalErrorBoundary}
      />
    </LexicalComposer>
  );
};
