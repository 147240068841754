import { List } from 'immutable';
import { $getRoot } from 'lexical';
import * as Models from 'models';
import { getTextStylesFromBrandStyle } from 'utils/brandStyles';
import { $applyHorizontalAlignment } from './horizontal-alignment';
import { $applyLineHeight } from './line-height';

export function $applyBrandStyle(
  brandStyle: Models.BrandStyleMap | undefined,
  brandStyleChanged: boolean | undefined,
): void {
  if (!brandStyle || brandStyleChanged) {
    // do nothing
    return;
  }

  // colors and fonts have no effect on lineHeight, textAlign
  const textStyles = getTextStylesFromBrandStyle(brandStyle, List([]), List([]));
  const { lineHeight, textAlign } = textStyles;
  $getRoot().getChildren().forEach((node) => {
    $applyLineHeight(node, lineHeight);
    $applyHorizontalAlignment(node, textAlign);
  });
}
