import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useLexicalIsTextContentEmpty } from '@lexical/react/useLexicalIsTextContentEmpty';
import { $selectAll } from 'lexical';
import { useEffect } from 'react';

type Props = {
  editMode: boolean;
};

export const SellectAllAutoFocusPlugin: React.FunctionComponent<Props> = ({ editMode }) => {
  const [editor] = useLexicalComposerContext();
  const isEmpty = useLexicalIsTextContentEmpty(editor);

  const editorUpdateOptions = {
    onUpdate:(): void => {
      if (isEmpty && editMode) {
        editor.focus();
      }
    },
  };
  useEffect(() => {
    if (!editMode) {
      return;
    }
    editor.update($selectAll, editorUpdateOptions);

  }, [editMode, editor]);

  return null;
};
