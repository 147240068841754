import { List } from 'immutable';
import { $getRoot, $isElementNode, ElementNode, LexicalNode } from 'lexical';
import { $createCustomListItemNode, $isCustomListItemNode } from 'modules/Lexical/nodes/CustomListItemNode';
import { $createCustomListNode, $isCustomListNode, CustomListNode } from 'modules/Lexical/nodes/CustomListNode';
import { $createCustomParagraphNode, $isCustomParagraphNode } from 'modules/Lexical/nodes/CustomParagraphNode';
import { TextHorizontalAlignmentType } from 'const';
import { TextBrandStyleField } from 'models';
import * as Models from 'models';
import { getTextStylesFromBrandStyle } from 'utils/brandStyles';
import { brandColorFromHEX } from 'utils/converters';
import { getBrandColor } from 'utils/editor';

export function $verifyListStyles(
  colors: Models.BrandColorsList,
): void {
  $getRoot().getChildren()
    .filter(node => $isCustomListNode(node))
    .forEach((node: CustomListNode) => {
      const nodeColor = node.getColor();
      if (nodeColor?.name) {
        const { name, HEX } = nodeColor;
        const color = getBrandColor(colors, name) ?? brandColorFromHEX(HEX);
        node.setColor(color?.toJS());
      }
    });
}

const isToggable = (node: LexicalNode): boolean => $isCustomListNode(node) || $isCustomParagraphNode(node) || $isCustomListItemNode(node);

export const $updateListBrandStyle = (
  brandStyle: Models.BrandStyleMap | undefined,
  brandStyleChanged: boolean | undefined,
  colors: Models.BrandColorsList,
): void => {
  if (!brandStyle || brandStyleChanged) {
    return;
  }

  const styles = getTextStylesFromBrandStyle(brandStyle, colors, List([]));
  // Buller color is defined either in the brand definition or in the style list file.
  // If this value includes a reference, it will be replaced by the corresponding hex value on the back-end side
  const bulletColorHex = styles[TextBrandStyleField.BULLET_COLOR];
  const textAlign = styles[TextBrandStyleField.TEXT_ALIGN];
  const isList = textAlign === TextHorizontalAlignmentType.UNORDERED_LIST;
  const color = getBrandColor(colors, bulletColorHex) ?? brandColorFromHEX(bulletColorHex);
  const originRoot = $getRoot();
  const stack: LexicalNode[] = [originRoot];
  const leaves: LexicalNode[][] = [];

  while (stack.length > 0) {
    const node = stack.pop();
    if (!$isElementNode(node)) {
      continue;
    }

    const nodeChildren: LexicalNode[] = node.getChildren() || [];
    if (nodeChildren.every(isToggable)) {
      stack.push(...nodeChildren);
    } else {
      leaves.push(nodeChildren);
    }
  }

  originRoot.clear();
  let root: ElementNode = originRoot;
  if (isList) {
    root = $createCustomListNode('bullet', 0, color?.toJS());
    originRoot.append(root);
  }

  const reversed = [...leaves].reverse();
  for (const children of reversed) {
    let parent: ElementNode;

    if (isList) {
      parent = $createCustomListItemNode();
    } else {
      parent = $createCustomParagraphNode();
      parent.setFormat(textAlign);
    }

    parent.append(...children);
    root.append(parent);
  }
};
