import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { useEffect, useRef } from 'react';
import { registerEditorPropsCommands } from './commands';
import { BaseProps } from './types';
import { $getBaseProps } from './utils';

export type { BaseProps } from './types';
export { BASE_PROPS_COMMAND } from './commands';

type Props = {
  onChange: (props: BaseProps) => void;
};

export function BasePropsPlugin(props: Props): null {
  const { onChange } = props;

  const isMountedRef = useRef(false);
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return registerEditorPropsCommands(editor);
  }, [editor]);

  useEffect(() => {
    const readEditorStateAndNotifyChange = (): void => onChange($getBaseProps());
    editor.getEditorState().read(readEditorStateAndNotifyChange);

    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(readEditorStateAndNotifyChange);
    });
  }, [editor, onChange]);

  useEffect(() => {
    isMountedRef.current = true;
  }, []);

  return null;
}
