import { Map as ImmutableMap } from 'immutable';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IMap } from 'typings/DeepIMap';
import { ImageSizeType, ProjectsConfig } from 'const';
import { isLocked } from 'containers/App/selectors';
import { getActiveLayer } from 'hooks/useActiveLayer';
import { useBeforeEditModeValue } from 'hooks/useBeforeEditModeValue';
import { useProjectType } from 'hooks/useProjectType';
import { BorderMap, CommonStylesMap, LayeredRelationsMap, PaddingMap, LayeredRelations, CombinedRelationStyles } from 'models';
import { ContentHeightCache } from 'services/contentHeightCache';
import { getExtraWidth } from 'utils/getExtraWidth';
import { getImageSize, GetImageSizeResult } from 'utils/styles/getImageSize';

import { stylesComparator } from 'utils/styles/stylesComparator';
import { ImageProps } from './models';
import { imageStylesToSource } from './styles';
import { useStyles } from './useStyles';

export const useImage = (props: ImageProps) => {
  const {
    cellHeight,
    cellWidth,
    docImageHeight,
    docImageWidth,
    editMode,
    finalizeRelations,
    flatColorsByRelationId,
    isAutoFitContent,
    layoutRelations,
    relation,
    toggleRowAndNeighborsHeight,
    toggleAutoFitContent,
    imageSrc,
    images,
    onImageLoad,
  } = props;

  const relationId = relation.get('id');
  const isFirstRendering = React.useRef(true);
  const container = React.useRef<HTMLDivElement>(null);
  const colors = flatColorsByRelationId.get(relationId);
  const activeLayer = getActiveLayer();
  const projectType = useProjectType();
  const isProjectLocked = useSelector(isLocked);

  const [imageStyles, imageStylesSetters, imageCSS] = useStyles(
    relation,
    activeLayer,
    { source: imageSrc, colors, images, cellWidth },
    ProjectsConfig[projectType],
  );
  const { border, extraHeight, extraWidth, padding, scale } = imageStyles;
  const cellInnerHeight = cellHeight ? cellHeight - extraHeight : undefined;
  const cellInnerWidth = cellWidth - extraWidth;

  const setImageHeightToCache = (value: number): void => {
    const contentHeights = ContentHeightCache.getInstance();
    contentHeights.setItem(relationId, value);
  };

  const toggleScale = (value: number, currentContainerWidth?: number): void => {
    const containerWidth = currentContainerWidth && (container?.current?.clientWidth ?? 0) - extraWidth;

    const {
      width: currentImageWidth,
      height: currentImageHeight,
    } = getImageSize({
      scale: value,
      docImageWidth,
      docImageHeight,
      cellInnerHeight,
      cellInnerWidth: containerWidth,
      isOnResize: Boolean(onResize),
    });

    imageStylesSetters.width(_.max([_.round(currentImageWidth), 1]));
    imageStylesSetters.height(_.max([_.round(currentImageHeight), 1]));
    imageStylesSetters.scale(value);
  };

  const getStyle = <T extends boolean = true>(
    value: number,
    toPx = true as T,
  ): GetImageSizeResult<T> => (
    getImageSize<T>({
      cellInnerHeight,
      cellInnerWidth,
      docImageHeight,
      docImageWidth,
      scale: value,
      toPx,
    })
  );

  const onResize = (value: number, isToggleRowAndNeighborsHeight = false): void => {
    const normalizedScale = Math.min(value, 1);
    const actualHeight = getStyle(normalizedScale, false).height;
    toggleScale(normalizedScale);
    if (isAutoFitContent) {
      setImageHeightToCache(actualHeight + extraHeight);
    }
    if (isToggleRowAndNeighborsHeight && (isAutoFitContent || (cellInnerHeight && cellInnerHeight < actualHeight))) {
      toggleRowAndNeighborsHeight(actualHeight + extraHeight);
    }
  };

  const recalculateImageHeight = (relations?: IMap<LayeredRelations<CombinedRelationStyles>> | undefined): void => {
    const newHeight = extraHeight + getStyle(scale, false).height;
    setImageHeightToCache(newHeight);
    toggleRowAndNeighborsHeight(newHeight, relations);
  };

  const onImageLoadWithHeightAdjustCallback = (): void => {
    if (isAutoFitContent) {
      recalculateImageHeight();
    }
    onImageLoad();
  };

  useEffect(() => {
    if (isAutoFitContent && !isFirstRendering.current) {
      recalculateImageHeight();
    }
  }, [isAutoFitContent]);

  useEffect(() => {
    // lock in false state indicates that upload has been finished and we can recalculate height
    if (!isProjectLocked) {
      recalculateImageHeight();
    }
  }, [isProjectLocked]);

  const toggleSize = (value: number, sizeType: ImageSizeType): void => {
    const containerWidth = (container.current?.clientWidth || 0) - extraWidth;
    const inputWidth = sizeType === ImageSizeType.WIDTH ? value : value * docImageWidth / docImageHeight;
    const newWidth = _.min([inputWidth, containerWidth]) || 0;
    const currentHeight = docImageHeight / docImageWidth * newWidth;
    if (isAutoFitContent) {
      setImageHeightToCache(currentHeight);
    }

    if (cellInnerHeight && currentHeight > cellInnerHeight) {
      toggleRowAndNeighborsHeight(currentHeight);
    }

    if (cellInnerHeight && isAutoFitContent && currentHeight < cellInnerHeight) {
      toggleRowAndNeighborsHeight(currentHeight);
    }

    const newScale = _.clamp(_.round(newWidth / _.max([containerWidth, 1]), 6), 0, 1);
    imageStylesSetters.width(_.max([_.round(newWidth), 1]));
    imageStylesSetters.height(_.max([_.round(currentHeight), 1]));
    imageStylesSetters.scale(newScale);
  };

  const prevCellWidth = useBeforeEditModeValue(cellWidth, editMode);
  const prevCellHeight = useBeforeEditModeValue(cellHeight, editMode);

  const updateRelation = (): LayeredRelationsMap | undefined => {
    const updatedRelation = relation.updateIn(
      ['styles', activeLayer],
      values => values.withMutations(
        value => imageStylesToSource(imageStyles, value)
          .set('isAutoFitContent', isAutoFitContent),
      ));

    if (
      prevCellWidth !== cellWidth ||
      prevCellHeight !== cellHeight ||
      !_.isEqualWith(
        updatedRelation.getIn(['styles', activeLayer]).toJS(),
        relation.getIn(['styles', activeLayer]).toJS(),
        stylesComparator,
      )
    ) {
      const updatedRelations = layoutRelations.set(updatedRelation.get('id'), updatedRelation);
      finalizeRelations(updatedRelations);

      return updatedRelations;
    }

    return undefined;
  };

  useEffect(
    () => {
      if (editMode) {
        const { width: newWidth, height: newHeight } = getStyle(scale, false);
        imageStylesSetters.width(_.max([newWidth, 1]));
        imageStylesSetters.height(_.max([newHeight, 1]));
      }
      if (isFirstRendering.current) {
        isFirstRendering.current = false;
      } else if (!editMode) {
        const updatedRelations = updateRelation();
        if (isAutoFitContent) {
          recalculateImageHeight(updatedRelations);
        }
      }
    },
    [editMode],
  );

  const checkScale = (newPadding: PaddingMap, newBorder?: BorderMap): void => {
    if (!container.current) {
      return;
    }

    const style = ImmutableMap({ padding, border }) as CommonStylesMap;
    const newStyle = ImmutableMap({ padding: newPadding, border: newBorder }) as CommonStylesMap;

    // need to recalculate the image scale in order to prevent scaling when the image can be fitted to free space
    const _cellWidth = container.current.clientWidth;
    // all possible width which the image can occupy
    const spaceWidth = _cellWidth - getExtraWidth(style);
    const newSpaceWidth = _cellWidth - getExtraWidth(newStyle);
    const newScale = _.round(spaceWidth * scale / newSpaceWidth, 6);
    const scaleToSet = newScale > 1 ? 1 : newScale;
    toggleScale(scaleToSet, newSpaceWidth);
  };

  const toggleAutoFitContentWithRelation = (): void => {
    updateRelation();
    toggleAutoFitContent();
  };

  return {
    container,
    onImageLoadWithHeightAdjustCallback,
    imageStyles,
    imageStylesSetters: {
      ...imageStylesSetters,
      border: (value): void => {
        checkScale(padding, value);
        imageStylesSetters.border(value);
      },
      padding: (value): void => {
        checkScale(value, border);
        imageStylesSetters.padding(value);
      },
      borderRadius: (value): void => {
        imageStylesSetters.borderRadius(value);
      },
      height: (value: number): void => toggleSize(value, ImageSizeType.HEIGHT),
      scale: toggleScale,
      width: (value): void => toggleSize(value, ImageSizeType.WIDTH),
    },
    imageCSS,

    cellInnerWidth,

    getStyle,
    onResize,

    toggleAutoFitContentWithRelation,
  };
};
