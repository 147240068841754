import { getCSSFromStyleObject, getStyleObjectFromCSS } from '@lexical/selection';
import { $getRoot, TextNode } from 'lexical';
import * as BrandDefinition from 'modules/BrandDefinition';
import * as Models from 'models';
import { getTextStylesFromBrandStyle } from 'utils/brandStyles';
import { brandColorFromHEX } from 'utils/converters';
import { getBrandColor } from 'utils/editor';
import { createFontColorStyles, createFontSizeStyles, FontPluginStyle } from './style';
import { brandColorFromStyle } from './utils';

export function $updateFontBrandStyle(
  brandStyle: Models.BrandStyleMap | undefined,
  brandStyleChanged: boolean | undefined,
  colors: Models.BrandColorsList,
  fonts: Models.BrandFontsList,
): void {
  if (!brandStyle || brandStyleChanged) {
    return;
  }

  const styles = getTextStylesFromBrandStyle(brandStyle, colors, fonts);
  const color = styles[Models.TextBrandStyleField.FONT_COLOR];
  const size = styles[Models.TextBrandStyleField.FONT_SIZE];
  const resultColor = getBrandColor(colors, color) ?? brandColorFromHEX(color);

  const styleObj = {
    ...createFontColorStyles(resultColor?.toJS() as BrandDefinition.BrandColorLike),
    ...createFontSizeStyles(size),
  };

  $getRoot().getAllTextNodes()
    .forEach((node: TextNode) => {
      const updatedStyles = {
        ...getStyleObjectFromCSS(node.getStyle()),
        ...styleObj,
      };

      node.setStyle(getCSSFromStyleObject(updatedStyles));
    });
}

export function $verifyFontStyles(
  colors: Models.BrandColorsList,
): void {
  $getRoot().getAllTextNodes()
    .forEach((node) => {
      // IN-PROGRESS: fonts should be taken into the account as well
      let changed = false;
      const style = { ...getStyleObjectFromCSS(node.getStyle()) };
      const color = brandColorFromStyle(style);
      if (color) {
        const newValue = BrandDefinition.getCSSColorFromBrandColor(color, colors);
        if (style[FontPluginStyle.COLOR] !== newValue) {
          style[FontPluginStyle.COLOR] = newValue;
          changed = true;
        }
      }

      if (changed) {
        node.setStyle(getCSSFromStyleObject(style));
      }
    });
}
