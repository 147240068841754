import { MarkNode } from '@lexical/mark';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { LexicalEditor, ParagraphNode, TextNode } from 'lexical';
import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import * as Constants from 'const';
import { BrandProps } from 'hooks/useBrandProps';
import * as Models from 'models';
import { BasePropsPlugin } from '../../Lexical/Plugins/BasePropsPlugin';
import { EditModePlugin } from '../../Lexical/Plugins/EditModePlugin';
import { EditorRefPlugin } from '../../Lexical/Plugins/EditorRefPlugin';
import { FontPlugin } from '../../Lexical/Plugins/FontPlugin';
import { mainTheme } from '../../Lexical/Theme/mainTheme';
import { LexicalOnChangeCTA } from '../hooks/useCallToActionEditor';
import { useLexicalEditorState } from '../hooks/useLexicalEditorState';
import { CustomParagraphNode } from '../nodes/CustomParagraphNode';
import { AbbreviationPlugin } from '../Plugins/AbbreviationPlugin';
import { CustomAutoFocusPlugin } from '../Plugins/CustomAutoFocusPlugin';
import { HistoryWithExtraStatePlugin } from '../Plugins/HistoryWithExtraStatePlugin';

const TEXT_LINE_HEIGHT = Constants.TextLineHeightValue.ONE_POINT_FIFTEEN;

const editorConfig = {
  namespace: 'call-to-action-editor',
  editable: false,
  theme: mainTheme,
  onError: (error): void => {
    throw error;
  },
  nodes: [
    TextNode,
    CustomParagraphNode,
    {
      replace: ParagraphNode,
      with: (): CustomParagraphNode => new CustomParagraphNode(TEXT_LINE_HEIGHT),
    },
    MarkNode,
  ],
};

type Props<T> = {
  onChange: LexicalOnChangeCTA;
  editMode: boolean;
  draftContent?: Draft.ContentState;
  lexicalState?: string;
  brandStyle: Models.BrandStyleMap | undefined;
  brandProps: Omit<BrandProps, 'brandStyles'>;
  historyExtraState: T;
  historyExtraStateSetter: (value: T) => void;
};

export const CallToActionEditor = forwardRef(function <T>(props: Props<T>, ref: React.Ref<LexicalEditor>) {
  const {
    editMode,
    draftContent: draftjsContent,
    lexicalState: lexicalStateStringified,
    brandStyle,
    brandProps,
    onChange,
    historyExtraState,
    historyExtraStateSetter,
  } = props;

  const editorRef = useRef<LexicalEditor>(null);
  useImperativeHandle(ref, () => editorRef.current as LexicalEditor);

  const initialEditorState = useLexicalEditorState({
    editor: editorRef.current,
    draftjsContent,
    brandProps,
    lexicalStateStringified,
  });

  return (
    <LexicalComposer initialConfig={{
      ...editorConfig,
      editable: editMode,
      editorState: initialEditorState,
    }}>
      <div className="editor-container">
        <RichTextPlugin
          contentEditable={<ContentEditable className="editor-input" />}
          ErrorBoundary={LexicalErrorBoundary}
        />
        {editMode && <HistoryWithExtraStatePlugin
          extraState={historyExtraState}
          extraStateSetter={historyExtraStateSetter}
        />}
        <EditorRefPlugin ref={editorRef} />
        <EditModePlugin editMode={editMode} />
        <BasePropsPlugin onChange={onChange.baseProps} />
        <FontPlugin
          onChange={onChange.font}
          brandStyle={brandStyle}
          colors={brandProps.colors}
          fonts={brandProps.fonts}
        />
        <AbbreviationPlugin onChange={onChange.abbreviations} />
        <CustomAutoFocusPlugin editMode={editMode}/>
      </div>
    </LexicalComposer>
  );
});
