import * as classNames from 'classnames';
import { $getRoot, createEditor } from 'lexical';
import _ from 'lodash';
import React from 'react';
import { DragSource, DragSourceCollector, DragSourceSpec } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import { editorConfig } from 'components/ArtboardAssets/Text/components/LexicalTextEditor';
import AssetActions from 'components/AssetActions';
import { useScrollIntoView } from 'components/AssetList/hooks';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { DragSourceType, EntityType, ModalType } from 'const';
import { useEditorToggle } from 'containers/EditorToggle/useEditorToggle';
import { Priority, useClickOutside } from 'hooks/useClickOutside';
import { AssetDragObject } from 'models';
import { getTextComponentNameWithoutReferences } from 'utils/getTextComponentNameWithoutReferences';
import { removeTags } from 'utils/removeTags';
import { trimText } from 'utils/trimText';
import * as Models from './models';
import styles from './styles.module.scss';

const TextComponentItemDragSource: DragSourceSpec<Models.ITextComponentItemProps, AssetDragObject> = {
  beginDrag(props) {
    const { toggleDragState, component } = props;
    const type = EntityType.TEXT;
    toggleDragState(DragSourceType.ASSET, type);

    return {
      type,
      component,
    };
  },
  endDrag(props) {
    const { toggleDragState } = props;
    toggleDragState();
  },
};

const collect: DragSourceCollector<Models.ITextComponentItemCollectedProps, Models.ITextComponentItemAllOwnProps> = (connect, monitor) => {
  return {
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  };
};

const TextComponentItem: React.FunctionComponent<Models.ITextComponentItemProps> = (props) => {
  const {
    allowDelete,
    assetIdToScroll,
    component,
    connectDragSource,
    connectDragPreview,
    deleteComponent,
    isDragging,
    isSelected,
    setAssetIdToScroll,
    addSelectedAssetPanelComponent,
    removeSelectedAssetPanelComponent,
    setSelectedTextComponent,
    showModal,
    textCount,
  } = props;

  const componentId = component.get('id');
  const { elementRef } = useScrollIntoView(componentId, assetIdToScroll, setAssetIdToScroll);
  React.useEffect(() => { connectDragPreview(getEmptyImage(), { captureDraggingState: true }); }, []);

  const { container } = useClickOutside<HTMLDivElement>(Priority.SELECTION, {
    active: isSelected,
    onClickOutside: () => removeSelectedAssetPanelComponent(componentId),
  });

  const { isLexicalMode, isDraftjsMode } = useEditorToggle();

  const openPreview = (): void => {
    setSelectedTextComponent(component);
    showModal(ModalType.TEXT_COMPONENT_DETAILS);
  };

  const deleteTextComponent = (): void => {
    const id = component.get('id');
    const entityType = component.get('entityType');
    deleteComponent(id, entityType);
  };

  const onSelectHandler = (): void => {
    addSelectedAssetPanelComponent(componentId);
  };

  const renderTextContent = (): JSX.Element => {
    let initialText = '';
    let endText = '';

    if (isLexicalMode) {
      const lexicalStateStringified = component.get('lexicalState');

      if (lexicalStateStringified) {
        const editor = createEditor(editorConfig);
        const parsedEditorState = editor.parseEditorState(lexicalStateStringified);
        initialText = parsedEditorState.read(() => $getRoot().getTextContent());
      }
    }

    // if the lexicalState is empty, use the DraftJs content
    if (isDraftjsMode || !initialText) {
      const rawContent = component.get('rawContent');
      let text = component.get('text');

      if (rawContent) {
        text = getTextComponentNameWithoutReferences(JSON.parse(rawContent));
      }

      initialText = removeTags(text);
    }

    endText = trimText(initialText);

    return (
      <div className={styles.content}>
        <div className={styles.iconWrapper}>
          <Icon type={IconType.TEXT} size="sm-md" color="primary" />
          {_.isFinite(textCount) && allowDelete && <div className={styles.counter}>{textCount}</div>}
        </div>
        <div className={styles.contentInfo}>
          <div className={styles.contentInfoPrimary}>
            {/* TODO: maybe create component, e.g. <TrimmedText text={text} rawContent={rawContent} /> for text output */}
            <span dangerouslySetInnerHTML={{ __html: initialText }} />
            <span className={styles.contentInfoPrimaryTruncation} dangerouslySetInnerHTML={{ __html: endText }} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className={classNames(styles.TextComponentItemWrapper, {
        [styles.TextComponentItemWrapper__bordered]: isDragging,
        [styles.TextComponentItemWrapper__active]: isSelected && allowDelete,
      })}
      ref={elementRef}
      onMouseDown={onSelectHandler}
    >
      {
        connectDragSource(
          <div
            className={classNames(styles.TextComponentItem, {
              [styles.TextComponentItem__hidden]: isDragging,
            })}
            ref={container}
          >
            {renderTextContent()}
            <AssetActions
              onDelete={allowDelete && deleteTextComponent}
              onPreviewOpen={openPreview}
            />
          </div>,
        )
      }
    </div>
  );
};

export default DragSource<Models.ITextComponentItemOwnProps, Models.ITextComponentItemCollectedProps, AssetDragObject>(
  DragSourceType.ASSET,
  TextComponentItemDragSource,
  collect,
)(TextComponentItem);
