import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { MarkNode } from '@lexical/mark';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { LexicalErrorBoundary } from '@lexical/react/LexicalErrorBoundary';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { TabIndentationPlugin } from '@lexical/react/LexicalTabIndentationPlugin';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { LexicalEditor, ParagraphNode, TextNode } from 'lexical';
import { useLexicalEditorState } from 'modules/Lexical/hooks/useLexicalEditorState';
import { CustomListItemNode } from 'modules/Lexical/nodes/CustomListItemNode';
import { CustomListNode } from 'modules/Lexical/nodes/CustomListNode';
import { CustomParagraphNode } from 'modules/Lexical/nodes/CustomParagraphNode';
import { ReferenceCitationNode } from 'modules/Lexical/nodes/ReferenceCitationNode';
import { AbbreviationPlugin } from 'modules/Lexical/Plugins/AbbreviationPlugin';
import { BasePropsPlugin } from 'modules/Lexical/Plugins/BasePropsPlugin';
import { CustomListPlugin } from 'modules/Lexical/Plugins/CustomListPlugin';
import { EditModePlugin } from 'modules/Lexical/Plugins/EditModePlugin';
import { EditorRefPlugin } from 'modules/Lexical/Plugins/EditorRefPlugin';
import { FontPlugin } from 'modules/Lexical/Plugins/FontPlugin';
import { HistoryWithExtraStatePlugin } from 'modules/Lexical/Plugins/HistoryWithExtraStatePlugin';
import { LinkNodePlugin } from 'modules/Lexical/Plugins/LinkNodePlugin';
import { ReferenceCitationPlugin } from 'modules/Lexical/Plugins/ReferenceCitationPlugin';
import { SellectAllAutoFocusPlugin } from 'modules/Lexical/Plugins/SellectAllAutoFocusPlugin';
import { TextBlockPlugin } from 'modules/Lexical/Plugins/TextBlockPlugin';
import { TextContentPlugin } from 'modules/Lexical/Plugins/TextContentPlugin';
import { mainTheme } from 'modules/Lexical/Theme/mainTheme';
import React, { forwardRef, RefObject, useImperativeHandle, useRef } from 'react';
import { DefaultTextBrandStyle } from 'const/Styles';
import { BrandProps } from 'hooks/useBrandProps';
import * as Models from 'models';
import { LexicalOnChange } from '../hooks/useLexicalTextEditor';

export const LEXICAL_TEXT_NODES = [
  TextNode,
  CustomParagraphNode,
  {
    replace: ParagraphNode,
    with: (): CustomParagraphNode => new CustomParagraphNode(),
  },
  HeadingNode,
  CustomListNode,
  {
    replace: ListNode,
    with: (node: ListNode): CustomListNode => new CustomListNode(node.__listType, node.__start),
  },
  CustomListItemNode,
  {
    replace: ListItemNode,
    with: (node: ListItemNode): CustomListItemNode => new CustomListItemNode(node.__value, node.__checked),
  },
  QuoteNode,
  MarkNode,
  AutoLinkNode,
  LinkNode,
  ReferenceCitationNode,
];

export const editorConfig = {
  namespace: 'text-editor',
  editable: false,
  theme: mainTheme,
  onError: (error): void => {
    throw error;
  },
  nodes: LEXICAL_TEXT_NODES,
};

export type LexicalTextEditorRef = {
  editor: LexicalEditor;
};

type Props<T> = {
  wrapperRef: RefObject<HTMLDivElement>;
  onChange: LexicalOnChange;
  editMode: boolean;
  draftContent?: Draft.ContentState;
  lexicalState?: string;
  brandStyle: Models.BrandStyleMap | undefined;
  brandStyleChanged: boolean | undefined;
  brandProps: BrandProps;
  historyExtraState: T;
  historyExtraStateSetter: (value: T) => void;
};

export const LexicalTextEditor = forwardRef(function <T extends object>(props: Props<T>, ref: React.Ref<LexicalEditor>) {
  const {
    wrapperRef,
    editMode,
    draftContent: draftjsContent,
    lexicalState,
    brandStyle,
    brandStyleChanged,
    brandProps,
    onChange,
    historyExtraState,
    historyExtraStateSetter,
  } = props;

  const editorRef = useRef<LexicalEditor>(null);
  useImperativeHandle(ref, () => editorRef.current as LexicalEditor);

  const initialEditorState = useLexicalEditorState({
    editor: editorRef.current,
    draftjsContent,
    brandProps,
    lexicalStateStringified: lexicalState,
  });

  return (
    <div ref={wrapperRef}>
      <LexicalComposer initialConfig={{
        ...editorConfig,
        editable: editMode,
        editorState: initialEditorState,
      }}>
        <div className="editor-container">
          <RichTextPlugin
            contentEditable={<ContentEditable className="editor-input" />}
            ErrorBoundary={LexicalErrorBoundary}
          />
          {editMode && <HistoryWithExtraStatePlugin
            extraState={historyExtraState}
            extraStateSetter={historyExtraStateSetter}
            mergeExtraStateKeys={['brandStyleChanged']}
          />}
          <ListPlugin />
          <LinkPlugin />
          <EditorRefPlugin ref={editorRef} />
          <EditModePlugin editMode={editMode} />
          <TabIndentationPlugin maxIndent={5} />
          <BasePropsPlugin onChange={onChange.baseProps} />
          <TextBlockPlugin
            brandStyle={brandStyle || brandProps.brandStyles.get(DefaultTextBrandStyle)}
            brandStyleChanged={brandStyleChanged}
            onChange={onChange.textBlock}
          />
          <CustomListPlugin
            onChange={onChange.bulletToggle}
            colors={brandProps.colors}
            fonts={brandProps.fonts}
            brandStyle={brandStyle}
            brandStyleChanged={brandStyleChanged}
          />
          <FontPlugin
            onChange={onChange.font}
            brandStyle={brandStyle}
            colors={brandProps.colors}
            fonts={brandProps.fonts}
          />
          <AbbreviationPlugin onChange={onChange.abbreviations} />
          <LinkNodePlugin onChange={onChange.link} />
          <TextContentPlugin onChange={onChange.textContent} />
          <ReferenceCitationPlugin />
          <SellectAllAutoFocusPlugin editMode={editMode}/>
        </div>
      </LexicalComposer>
    </div>
  );
});
