import { $isMarkNode, MarkNode } from '@lexical/mark';
import { $getRoot, $isElementNode, createEditor, ElementNode } from 'lexical';
import { $removeNodeAbbreviation } from 'modules/Lexical/Plugins/AbbreviationPlugin/utils';
import { editorConfig } from 'components/ArtboardAssets/Text/components/LexicalTextEditor';
import * as Models from 'models';

function $getAbbreviations(node: ElementNode): MarkNode[] {
  const markNodes = $isMarkNode(node) ? [node] : [];

  if ($isElementNode(node)) {
    const children = node.getChildren();
    children.forEach(child => markNodes.push(...$getAbbreviations(child as ElementNode)));
  }

  return markNodes;
}

/**
 * Main function that removes abbreviations from the lexical state if they are not present
 * in the textAbbreviationDocuments. It converts the lexical state from a JSON string before processing.
 *
 * @param textAbbreviationDocuments - A collection of documents for validation.
 * @param lexicalState - A JSON string representing the lexical state.
 * @returns A JSON string representing the updated lexical state.
 */
export function removeAbbreviationsIfNotPresent(
  textAbbreviationDocuments: Models.TextAbbreviationDocumentsArray,
  lexicalState: string,
): string {
  const editor = createEditor(editorConfig);
  const editorState = editor.parseEditorState(lexicalState);
  editor.setEditorState(editorState);

  editor.update(() => {
    const root = $getRoot();
    const markNodes = $getAbbreviations(root);

    markNodes.forEach((markNode) => {
      const [abbreviationId] = markNode.getIDs();
      const isTextAbbreviationDocumentPresent = Boolean(textAbbreviationDocuments[abbreviationId]);
      if (!isTextAbbreviationDocumentPresent) {
        $removeNodeAbbreviation(markNode);
      }
    });
  }, { discrete: true });

  return JSON.stringify(editor.getEditorState());
}

export function hasAbbreviations(lexicalState: string): boolean {
  const editor = createEditor(editorConfig);
  const editorState = editor.parseEditorState(lexicalState);

  return editorState.read(() => {
    const root = $getRoot();
    const markNodes = $getAbbreviations(root);

    return Boolean(markNodes.length);
  });
}
