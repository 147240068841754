import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { OrderedSet } from 'immutable';
import { useEffect } from 'react';
import * as Constants from 'const';
import * as Models from 'models';
import { $verifyFontStyles, $updateFontBrandStyle } from './brandStyle';
import { registerCommands } from './commands';
import { $getBrandColor, $getBrandFont, $getFontSize, $getInlineStyle } from './utils';

export { FONT_COMMAND } from './commands';

export type FontProps = {
  brandColor: Models.BrandColorMap | undefined;
  brandFont: Models.FontFamily | undefined;
  size: number | undefined;
  inlineStyle: OrderedSet<Constants.InlineStyle>;
};

type Props = {
  brandStyle: Models.BrandStyleMap | undefined;
  brandStyleChanged: boolean | undefined;
  colors: Models.BrandColorsList;
  fonts: Models.BrandFontsList;
  onChange: (props: FontProps) => void;
};

// IN-PROGRESS: useEffect for change in fonts
export function FontPlugin(props: Props): null {
  const { onChange, brandStyle, colors, fonts, brandStyleChanged } = props;
  const [editor] = useLexicalComposerContext();

  useEffect(
    () => registerCommands(editor, brandStyle, colors, fonts),
    [editor, brandStyle, colors],
  );

  useEffect(() => {
    editor.update(() => {
      $updateFontBrandStyle(brandStyle, brandStyleChanged, colors, fonts);
    });
  }, [editor, brandStyle, brandStyleChanged]);

  useEffect(() => {
    editor.update(() => {
      $verifyFontStyles(colors);
    });
  }, [editor, colors, fonts]);

  useEffect(() => {
    const $readAndNotifyChange = (): void => onChange({
      brandColor: $getBrandColor(colors),
      brandFont: $getBrandFont(),
      size: $getFontSize(),
      inlineStyle: OrderedSet($getInlineStyle()),
    });

    editor.getEditorState().read($readAndNotifyChange);

    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read($readAndNotifyChange);
    });
  }, [editor, onChange, colors]);

  return null;
}
