import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $createRangeSelection, $setSelection, LexicalEditor } from 'lexical';
import { useEffect } from 'react';
import { $createReferenceCitationNode } from '../nodes/ReferenceCitationNode';

function getRange(event: DragEvent): Range | null {
  // is fixed in TS v.5.7, remove after upgrade
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const caret = document.caretPositionFromPoint(event.clientX, event.clientY);
  if (caret) {
    const range = document.createRange();
    // is fixed in TS v.5.7, remove after upgrade
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    range.setStart(caret.offsetNode, caret.offset);

    return range;
  }

  return null;
}

function dropHandler(editor: LexicalEditor) {
  return (event: DragEvent): boolean => {
    event.preventDefault();
    const referenceId = event.dataTransfer?.getData('text/plain');
    if (!referenceId) {
      return false;
    }

    editor.update(
      () => {
        const referenceNode = $createReferenceCitationNode(referenceId);

        const selection = $createRangeSelection();
        const range = getRange(event);
        if (range) {
          selection.applyDOMRange(range);
          selection.insertNodes([referenceNode]);

          $setSelection(null);
        }
      },
      {
        discrete: true,
      });

    return true;
  };
}

export const ReferenceCitationPlugin = (): null => {
  const [editor] = useLexicalComposerContext();
  const root = editor.getRootElement();

  useEffect(() => {
    if (!root) {
      return;
    }

    const handlerWithContext = dropHandler(editor);

    root.addEventListener('drop', handlerWithContext);

    return () => {
      root.removeEventListener('drop', handlerWithContext);
    };
  }, [root, editor]);

  return null;
};
