import { LexicalEditor } from 'lexical';
import { LexicalOnChangeCTA } from 'modules/Lexical/hooks/useCallToActionEditor';
import React, { createContext, ReactNode, useContext } from 'react';
import { IList } from 'typings/DeepIMap';
import { BrandColor, BrandFont } from 'models';
import { HistoryExtraStateHook } from '../hooks/useHistoryExtraState';

type ContextType = {
  lexicalEditorRef: React.RefObject<LexicalEditor>;
  lexicalHookOnChange: LexicalOnChangeCTA;
  id: string;
  cssButton: string;
  editMode: boolean;
  editorWrap: React.RefObject<HTMLDivElement>;
  editorState: Draft.EditorState;
  lexicalState?: string;
  returnFocusToEditor: () => void;
  styles: React.CSSProperties;
  brandProps: {
    colors: IList<BrandColor>;
    fonts: IList<BrandFont>;
  };
  history: HistoryExtraStateHook;
};

const Context = createContext<ContextType | null>(null);

export const useLexicalCTAEditorContext = (): ContextType => {
  const value = useContext(Context);

  if (!value) {
    throw new Error('must be used within a LexicalCTAEditorProvider');
  }

  return value;
};

type ProviderProps = {
  value: ContextType;
  children: ReactNode;
};

export const LexicalCTAEditorProvider: React.FC<ProviderProps> = ({ children, value }) => {
  return <Context.Provider value={value}>{children}</Context.Provider>;
};
