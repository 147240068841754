import * as BrandDefinition from 'modules/BrandDefinition';
import * as Constants from 'const';
import * as Models from 'models';
import { toPx } from 'utils/toPx';
import { brandColorToStyle } from './utils';

export enum FontPluginStyle {
  BRAND_COLOR_HEX = 'brand-color-hex', // CUSTOM property
  BRAND_COLOR_NAME = 'brand-color-name', // CUSTOM property
  BRAND_COLOR_TINT = 'brand-color-tint', // CUSTOM property
  COLOR = 'color',
  BRAND_FONT_NAME = 'brand-font-name', // CUSTOM property
  FONT_FAMILY = 'font-family',
  CHARACTER_STYLE_NAME = 'character-style-name', // CUSTOM property
  FONT_SIZE = 'font-size',
  FONT_WEIGHT = 'font-weight',
  FONT_STYLE = 'font-style',
}

export function createFontColorStyles(color: BrandDefinition.BrandColorLike | undefined): Record<string, string> {
  return {
    [FontPluginStyle.COLOR]: color?.HEX ? color.HEX : Constants.DefaultCustomStyle.FONT_COLOR,
    ...brandColorToStyle(color),
  };
}

export function createFontSizeStyles(value: number): Record<string, string> {
  const px = toPx(value);
  const styles = {};

  if (px) {
    styles[FontPluginStyle.FONT_SIZE] = px;
  }

  return styles;
}

type FontProps = {
  fontFamily?: string;
  brandFontName?: string;
  characterStyleName?: string;
  fontStyle?: string;
  fontWeight?: string;
};

export function createFontStyles(
  fontProps: FontProps,
  fonts: Models.BrandFontsList,
): Record<string, string> {
  const styleObj = {};
  const { fontFamily, characterStyleName, fontStyle, fontWeight } = fontProps;

  if (fontFamily) {
    styleObj[FontPluginStyle.BRAND_FONT_NAME] = fontFamily;
    styleObj[FontPluginStyle.FONT_FAMILY] = BrandDefinition
      .getCSSFontFamilyFromBrandFont(fontFamily, characterStyleName, fonts);
  }

  if (characterStyleName) {
    styleObj[FontPluginStyle.CHARACTER_STYLE_NAME] = characterStyleName;
  }

  if (fontStyle) {
    styleObj[FontPluginStyle.FONT_STYLE] = fontStyle;
  }

  if (fontWeight) {
    styleObj[FontPluginStyle.FONT_WEIGHT] = fontWeight;
  }

  return styleObj;
}
