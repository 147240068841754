import classNames from 'classnames';
import { CellAction, useCellActionsExecutor } from 'context/CellActions';
import { useEditorLexical } from 'modules/Lexical/hooks/useCallToActionEditor';
import { useLexicalUndo } from 'modules/Lexical/hooks/useLexicalUndo';
import React, { useRef } from 'react';
import AccessibleDiv from 'components/AccessibleDiv/AccessibleDiv';
import Toolbar from 'components/Toolbar/CallToAction';
import UndoPortal from 'components/UndoRedo/UndoPortal';
import * as Constants from 'const';
import { useEditorToggle } from 'containers/EditorToggle/useEditorToggle';
import { getActiveLayer } from 'hooks/useActiveLayer';
import useBrandProps from 'hooks/useBrandProps';
import { useProjectType } from 'hooks/useProjectType';
import { AssetAlignment } from 'models';
import { allowLinkRedirect } from 'utils/allowLinkRedirect';
import * as styleUtils from 'utils/styles';
import { useHistoryGlobalState } from '../../../hooks/useHistoryGlobalState';
import editorWrapperCss from '../editor-wrapper.module.scss';
import DraftjsButton from './components/DraftjsButton';
import LexicalButton from './components/LexicalButton';
import ResizableButton from './components/ResizableButton';
import { DraftjsCTAEditorProvider } from './context/DraftjsCTAEditor';
import { LexicalCTAEditorProvider } from './context/LexicalCTAEditor';
import { ResizeProvider } from './context/Resize';
import { EditorHook } from './editor';
import { useCallToAction } from './hooks';
import { useEditorDraftjs } from './hooks/useEditorDraftjs';
import { useHistoryExtraState } from './hooks/useHistoryExtraState';
import { useLink } from './hooks/useLink';
import { useResizeObserver } from './hooks/useResizeObserver';
import { useSave } from './hooks/useSave';
import { useSizeReset } from './hooks/useSizeReset';
import { useStyles } from './hooks/useStyles';
import { useUndo } from './hooks/useUndo';
import { CallToActionProps } from './models';
import css from './styles.module.scss';
import { wrapEditorSettersWithUndo, wrapUndoMiddleware } from './utils/draftjs-undo';

export function getCallToActionDOMId(relationId: string): string {
  return `call-to-action-cell-${relationId}`;
}

const CallToAction: React.FunctionComponent<CallToActionProps> = (props) => { //NOSONAR
  const {
    cellHeight,
    cellWidth,
    disableCellWidthEditing,
    document,
    editMode,
    isIncreasingWidthDisabled,
    layoutId,
    maxCellHeight,
    minCellHeight,
    relation,
    toggleColumnWidth,
    toggleRowHeight,
    toggleAutoFitContent,
    isAutoFitContent,
    images,
  } = props;

  const projectType = useProjectType();
  const activeLayer = getActiveLayer();
  const relationId = relation.get('id');
  const brandProps = useBrandProps(relationId);

  const editorWrap = useRef<HTMLDivElement>(null);

  const stylesHook = useStyles(
    relation,
    activeLayer,
    { colors: brandProps.colors, images, defaultAssetBackgroundColor: Constants.DefaultCallToActionBackgroundColor },
    Constants.ProjectsConfig[projectType],
    cellWidth,
  );

  const { styles, css: stylesCSS, getMaxWidth } = stylesHook;

  const sizeResetHook = useSizeReset(props, stylesHook);

  const { isLexicalMode, isBothMode, isDraftjsMode } = useEditorToggle();
  const lexicalHook = useEditorLexical(sizeResetHook, editorWrap);
  const draftjsHook = useEditorDraftjs(props, brandProps, sizeResetHook, editorWrap);
  const editorHook: EditorHook = isLexicalMode ? lexicalHook : draftjsHook;

  const { link, toggleLink } = useLink(document);

  const {
    container,
    modifiedStylesSetters: stylesSetters,
    correctedHeight,
    correctedWidth,
    getResizeComponentHeight,
    getResizeComponentMinHeight,
    getResizeComponentWidth,
    finishResizing,
    onResize,
  } = useCallToAction(props, stylesHook, sizeResetHook, editorWrap);

  useSave(props, stylesHook, sizeResetHook, editorHook, link);

  useResizeObserver(editorWrap, styles, stylesSetters, editMode);

  const {
    setCursorOnAbbreviation,
    applySelection,
    returnFocusToEditor,
  } = editorHook;

  const history = useHistoryExtraState(
    props,
    stylesHook,
    { link, toggleLink, height: correctedHeight, width: correctedWidth },
  );
  const lexicalUndo = useLexicalUndo(lexicalHook.editorRef.current);
  const draftjsUndo = useUndo(
    editMode,
    draftjsHook,
    stylesHook,
    { toggleAutoFitContent, toggleLink, toggleRowHeight, toggleColumnWidth },
    history.extraState,
  );
  const undoHook = isLexicalMode ? lexicalUndo : draftjsUndo;
  useHistoryGlobalState(editMode, undoHook.isUndoDisabled);

  const handleClick = (event: React.MouseEvent): void => {
    if (allowLinkRedirect(event) && link) {
      window.open(link, '_blank');
    }
  };

  useCellActionsExecutor(CellAction.SET_CURSOR_ON_ABBREVIATION, setCursorOnAbbreviation);
  useCellActionsExecutor(CellAction.APPLY_SELECTION, applySelection);

  const buttonStyles = {
    ...stylesCSS.element,
    ...styleUtils.getButtonSize(correctedHeight, correctedWidth, editMode),
  };

  const buttonProps = {
    id: relationId,
    editorState: draftjsHook.editorState,
    editorRef: draftjsHook.editorRef,
    lexicalEditorRef: lexicalHook.editorRef,
    lexicalHookOnChange: lexicalHook.onChange,
    cssButton: css.button,
    styles: buttonStyles,
    editMode,
    editorWrap,
    onEditorChange: draftjsHook.onEditorChange,
    returnFocusToEditor,
    undoHook: draftjsUndo,
    brandProps,
  };

  const resizeProps = {
    alignment: styles.alignment.toJS() as AssetAlignment,
    container: container.current ?? undefined,
    startResizing: isLexicalMode ? undefined : (currentWidth: number, currentHeight: number): void => {
      draftjsUndo.undoStackMiddleware(() => {}, 'width', true)(currentWidth);
      draftjsUndo.undoStackMiddleware(() => {}, 'height', true)(currentHeight);
    },
    finishResizing,
    onResize,
    width: getResizeComponentWidth(),
    height: getResizeComponentHeight(),
    minHeight: getResizeComponentMinHeight(),
  };

  return (
    <AccessibleDiv
      className={css.CallToAction}
      onClick={handleClick}
      ref={container}
      style={stylesCSS.container}
    >
      <DraftjsCTAEditorProvider value={buttonProps}>
        <LexicalCTAEditorProvider value={{
          id: relation.get('id'),
          editorState: draftjsHook.editorState,
          lexicalState: document.get('lexicalState'),
          lexicalEditorRef: lexicalHook.editorRef,
          lexicalHookOnChange: lexicalHook.onChange,
          cssButton:css.button,
          styles: buttonStyles,
          editMode,
          editorWrap,
          returnFocusToEditor,
          brandProps,
          history,
        }}>
          <ResizeProvider value={resizeProps}>
            <div className={classNames({
              [editorWrapperCss.editorModeBothWrapper]: isBothMode,
              [editorWrapperCss.editorModeLexicalWrapper]: isLexicalMode,
            })}>
              {(isDraftjsMode || isBothMode) && (
                <>
                  {editMode
                    ? <ResizableButton><DraftjsButton/></ResizableButton>
                    : <DraftjsButton/>
                  }
                </>
              )}

              {(isLexicalMode || isBothMode) && (
                <div className={editorWrapperCss.lexicalEditor}>
                  {(editMode && isLexicalMode)
                    ? <ResizableButton><LexicalButton/></ResizableButton>
                    : <LexicalButton/>
                  }
                </div>
              )}
            </div>
          </ResizeProvider>
        </LexicalCTAEditorProvider>
      </DraftjsCTAEditorProvider>
      {
        editMode &&
          <>
            <UndoPortal
              undo={undoHook.undo}
              redo={undoHook.redo}
              isRedoDisabled={undoHook.isRedoDisabled}
              isUndoDisabled={undoHook.isUndoDisabled}
            />
            <Toolbar
              editorProps={editorHook.props}
              editorSetters={
                isLexicalMode
                  ? lexicalHook.setters
                  : wrapEditorSettersWithUndo(draftjsUndo.undoStackMiddleware, draftjsHook.setters)
              }
              styles={styles}
              stylesSetters={
                isLexicalMode
                  ? stylesSetters
                  : wrapUndoMiddleware(draftjsUndo.undoStackMiddleware, stylesSetters)
              }
              projectType={projectType}
              returnFocusToEditor={returnFocusToEditor}
              cellHeight={cellHeight}
              maxCellHeight={maxCellHeight}
              minCellHeight={minCellHeight}
              toggleCellHeight={
                isLexicalMode
                  ? toggleRowHeight
                  : draftjsUndo.undoStackMiddleware(toggleRowHeight, 'cellHeight') as (newHeight: number) => number
              }
              cellWidth={cellWidth}
              toggleColumnWidth={
                isLexicalMode
                  ? toggleColumnWidth
                  : draftjsUndo.undoStackMiddleware(toggleColumnWidth, 'cellWidth') as (newWidth: number) => number
              }
              link={link}
              toggleLink={
                isLexicalMode
                  ? toggleLink
                  : draftjsUndo.undoStackMiddleware(toggleLink, 'link')
              }
              layoutId={layoutId}
              disableCellWidthEditing={disableCellWidthEditing}
              height={getResizeComponentHeight()}
              width={getResizeComponentWidth()}
              maxWidth={getMaxWidth()}
              isIncreasingWidthDisabled={isIncreasingWidthDisabled}
              toggleAutoFitContent={
                isLexicalMode
                  ? toggleAutoFitContent
                  : draftjsUndo.undoStackMiddleware(toggleAutoFitContent, 'isAutoFitContent')
              }
              isAutoFitContent={isAutoFitContent}
            />
          </>
      }
    </AccessibleDiv>
  );
};

export default CallToAction;
