import { EditorState } from 'draft-js';
import _ from 'lodash';
import { TextDetailsEditor } from 'modules/draftjs/components/TextDetailsEditor';
import { compositeDecorator } from 'modules/draftjs/decorators';
import { TextPreviewEditor } from 'modules/Lexical/components/TextPreviewEditor';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Details } from 'components/Details';
import Icon from 'components/Icon';
import { IconType } from 'components/Icon/constants';
import { useEditorToggle } from 'containers/EditorToggle/useEditorToggle';
import { hideModal } from 'containers/ModalManager/actions';
import { referencesDataForDocumentOnAssetPanel, selectedTextComponent } from 'containers/ProjectPanel/selectors';
import { addReferenceCitationsOrder } from 'utils/addReferenceCitationsOrder';
import * as editorUtils from 'utils/editor';
import { intlGet } from 'utils/intlGet';
import styles from './styles.module.scss';

type Props = { modalWindowId: string };

const TextComponentDetails = (props: Props): JSX.Element => {
  const { modalWindowId } = props;

  const dispatch = useDispatch();
  const referencesNumbersOrder = useSelector(referencesDataForDocumentOnAssetPanel());
  const selectedTextComponentData = useSelector(selectedTextComponent);

  const { isLexicalMode, isDraftjsMode } = useEditorToggle();
  const onClose = (): void => {
    dispatch(hideModal(modalWindowId));
  };

  const editorState = _.flow(
    editorUtils.convertTextComponentToRawEditorState,
    currentEditorState => addReferenceCitationsOrder(currentEditorState, referencesNumbersOrder),
    currentEditorState => EditorState.set(currentEditorState, { decorator: compositeDecorator }),
  )(selectedTextComponentData);

  return (
    <div className={styles.TextComponentDetails}>
      <Details
        onClose={onClose}
        title={intlGet('TextComponentDetails.Value', 'Title')}
      >
        <div className={styles.textInfo}>
          <Icon type={IconType.TEXT} size="sm-md" color="primary" />
          <div className={styles.htmlText}>

            {isDraftjsMode && <TextDetailsEditor editorState={editorState} />}

            {isLexicalMode && <TextPreviewEditor
              draftjsContent={editorState.getCurrentContent()}
              lexicalState={selectedTextComponentData.get('lexicalState')} />}
          </div>
        </div>
      </Details>
    </div>
  );
};

export default TextComponentDetails;
