import Draft from 'draft-js';

import { $getRoot, createEditor } from 'lexical';
import { editorConfig } from 'components/ArtboardAssets/Text/components/LexicalTextEditor';
import * as Models from 'models';
import { getValue, getValues } from './getter';
import { removeTags } from './removeTags';

export function getCleanText(textComponent: Models.TextComponent | Models.TextComponentMap): string {
  const { rawContent, text } = getValues(textComponent, ['rawContent', 'text']);

  return rawContent
    ? Draft.EditorState.createWithContent(Draft.convertFromRaw(JSON.parse(rawContent))).getCurrentContent().getPlainText()
    : removeTags(text);
}

export function getCleanTextLexical(textComponent: Models.TextComponent | Models.TextComponentMap): string {
  const lexicalStateStringified = getValue(textComponent, 'lexicalState') as string;

  if (!lexicalStateStringified) {
    return '';
  }

  const editor = createEditor(editorConfig);
  const editorState = editor.parseEditorState(lexicalStateStringified);

  return editorState.read(() => $getRoot().getTextContent());
}
