import { SerializedLinkNode } from '@lexical/link';
import { SerializedMarkNode } from '@lexical/mark/MarkNode';
import { RawDraftEntityRange, RawDraftInlineStyleRange } from 'draft-js';
import { ElementFormatType, SerializedLexicalNode } from 'lexical';
import * as Models from 'models';
import { SerializedCustomListNode } from '../nodes/CustomListNode';

export type TCombination = {
  position: number[];
  style: string[] | null;
  type: string | null;
  data?: unknown;
};

export enum BulletListType {
  BULLET = 'bullet',
  LIST = 'list',
  CUSTOM_LIST = 'custom-list',
  LIST_ITEM = 'listitem',
  UL_TAG = 'ul',
  UNDORDERED_LIST_ITEM = 'unordered-list-item',
}

export type TSlicedText = {
  text: string;
  styles: string[];
  type?: string;
  roundData?: { url?: string; id?: string };
};

export enum ConvertStylesEnum {
  UNDERLINE = 'UNDERLINE',
  SUB = 'SUBSCRIPT',
  SUP = 'SUPERSCRIPT',
}

export enum LexicalFormatCodeEnum {
  BOLD = 1,
  ITALIC = 2,
  UNDERLINE = 8,
  DEFAULT = 0,
  SUB = 32,
  SUP = 64,
}

export type TRanges = RawDraftEntityRange & RawDraftInlineStyleRange;

export enum LexicalFieldDescribes {
  RTL = 'rtl',
  LTR = 'ltr',
}

export type DraftjsEntityData = {
  [key: string]: string;
};

export type RawDraftContentBlockData = {
  lineHeight?: number;
  [key: string]: unknown;
};

export type TSerializedLexicalNode = SerializedLexicalNode & {
  detail: number;
  format: number;
  mode: string;
  style: string;
  text: string;
};

export interface RawContentLine {
  children: SerializedMarkNode[] | SerializedLinkNode[] | TSerializedLexicalNode[] | SerializedCustomListNode[];
  lineHeight?: number;
  textFormat?: number;
  detail?: number;
  format: ElementFormatType;
  mode?: string;
  style?: string;
  text?: string;
  type: string;
  version: number;
  indent: number;
  color?: Models.BrandColor;
  direction: LexicalFieldDescribes | null;
  ids?: string[];
}
