import Draft from 'draft-js';
import { ElementFormatType, SerializedEditorState, TextNode } from 'lexical';
import { DraftEntity } from 'const/Draft';
import * as Models from 'models';
import { CustomParagraphNode } from '../nodes/CustomParagraphNode';
import { lexicalImportBase } from './constants';
import { createLinkNode } from './nodes/Link';
import { createMarkNode } from './nodes/Mark';
import { BulletListType, LexicalFieldDescribes, RawDraftContentBlockData, TCombination, TRanges, TSlicedText } from './types';
import { getStyleArray, getFormateCode, getParagraphFormat, addNoWrapStyles, formatListNodes } from './utils';

export interface SerializedDocument {
  /** The serialized editorState produced by editorState.toJSON() */
  editorState: SerializedEditorState;
  /** The time this document was created in epoch milliseconds (Date.now()) */
  lastSaved: number;
  /** The source of the document, defaults to Lexical */
  source: 'Lexical';
  /** The version of Lexical that produced this document */
  version: string;
}

const createCombinationsReducer = (
  entityMap: { [key: string]: Draft.RawDraftEntity },
) => (acc: TCombination[], curr: TRanges): TCombination[] => {
  const child: TCombination = {
    type: curr.key !== undefined ? entityMap[curr.key].type : null,
    style: curr.style ? [curr.style] : null,
    position: [curr.offset, curr.length],
    data: curr.key !== undefined ? entityMap[curr.key].data : null,
  };

  const index =
    acc.length > 0 && child.style
      ? acc.findIndex(
        el =>
          el.position[0] === curr.offset && el.position[1] === curr.length,
      )
      : -1;

  if (index >= 0) {
    acc[index] = {
      ...acc[index],
      style: [...(acc[index].style || []), curr.style],
    };

    return acc;
  }

  return [...acc, child];
};

export const lexicalRawConverter = (
  rawContent: Draft.RawDraftContentState,
  colors: Models.BrandColorsList,
  fonts: Models.BrandFontsList,
): SerializedDocument => {
  const { blocks, entityMap } = rawContent;
  const lines = blocks.map((line) => {
    const { inlineStyleRanges, entityRanges, data, depth } = line;
    const nodeType = line.type === BulletListType.UNDORDERED_LIST_ITEM ? BulletListType.LIST_ITEM : CustomParagraphNode.getType();

    const combinations = [
      ...inlineStyleRanges,
      ...entityRanges,
    ].reduce(createCombinationsReducer(entityMap), [] as TCombination[]);

    const round: number[] = combinations.reduce((acc, item) => {
      const value = [item.position[0], item.position[0] + item.position[1]];

      return [...(new Set([...acc, ...value]))];
    }, []).sort((a, b) => (a - b));

    let slicedText = [] as TSlicedText[];

    round.forEach((point, i: number) => {
      const nextPoint = round[i + 1];
      const entity = entityRanges.find(entityRange => entityRange.offset <= point && (entityRange.offset + entityRange.length) > point);
      const entityData = entity?.key !== undefined ? {
        type: entityMap[entity.key].type,
        roundData: entityMap[entity.key].data,
      } : {};

      // Fix this algoritm. Path count not equal of count of combinations array
      if (nextPoint) {
        slicedText = [...slicedText, {
          ...entityData,
          text: line.text.slice(point, round[i + 1]),
          styles: combinations.reduce((acc, curr) => {
            const sum = curr.position[0] + curr.position[1];

            return curr.style && (curr.position[0] <= point && sum > point)
              ? [...acc, ...curr.style]
              : acc;
          }, []),
        }];
      }
    });

    const children = slicedText.reduce((acc, currText) => {
      const { styles, text, type, roundData } = currText;

      const child = {
        detail: 0,
        format: getFormateCode(styles),
        mode: 'normal',
        style: `${getStyleArray(styles, colors, fonts).join('; ')};`,
        text,
        type: TextNode.getType(),
        version: 1,
      };

      switch (type) {
        case DraftEntity.ABBREVIATION:
          return [...acc, createMarkNode(child, roundData)];
        case DraftEntity.LINK:
          return [...acc, createLinkNode(child, roundData)];
        case DraftEntity.NO_WRAP:
          return [...acc, addNoWrapStyles(child)];
        default:
          return [...acc, child];
      }
    }, []);

    const nodeFormat = nodeType === CustomParagraphNode.getType() ? getParagraphFormat(line) : undefined;
    const lineHeight = (data as RawDraftContentBlockData)?.lineHeight;

    return {
      children,
      direction: LexicalFieldDescribes.LTR,
      type: nodeType,
      format: nodeFormat as ElementFormatType,
      version: 1,
      textFormat: 0,
      indent: depth,
      lineHeight,
    };
  });

  const formatedLines = formatListNodes(lines, colors);
  lexicalImportBase.editorState.root.children = formatedLines;

  return lexicalImportBase as unknown as SerializedDocument;
};
