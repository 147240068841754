import Draft from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import _ from 'lodash';
import { selectAbbreviationsData } from 'modules/Abbreviations/store/selectors';
import {
  Features,
  UseDraftjsEditorReturnType,
  applyEditorStateFontStylesForBrandStyles,
  useDraftjsEditor,
} from 'modules/draftjs';
import { DraftjsEditorStateSetter, flowWithApplyDraftjsEditorDecorator } from 'modules/draftjs/hooks/useDraftjsEditorState';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useDraftjsEditorState } from 'components/ArtboardAssets/Text/hooks/useDraftjsEditorState';
import * as Constants from 'const';
import * as DocumentModels from 'containers/Documents/models';
import { BrandProps } from 'hooks/useBrandProps';
import * as Models from 'models';
import { removeReferenceCitationsOrder } from 'utils/addReferenceCitationsOrder';
import * as editorUtils from 'utils/editor';
import { getReferenceNumberOrder } from 'utils/getReferenceNumberOrder';
import { getInitialEditorState, setColorForListBullets } from '../utils/editor';
import { getPrioritizedRelation } from '../utils/relation';
import { TextEditorHook, TextEditorSetters } from './TextEditorHook';
import useStyles from './useStyles';

const draftjsFeatures = [
  Features.APPLY_HACKS,
  Features.ABBREVIATIONS,
  Features.DECORATORS,
  Features.BLOCK,
  Features.BULLET,
  Features.FONT_COLOR,
  Features.INLINE_STYLE_EXTENDED,
  Features.APPLY_BRANDSTYLE_FOR_EXTENDED_INLINE_STYLE,
  Features.LINK,
] as const;

type DraftjsHook = UseDraftjsEditorReturnType<typeof draftjsFeatures>;

type HookProps = {
  activeLayer: Constants.Layer;
  editMode: boolean;
  document: Models.TextComponentMap;
  projectType: Constants.ProjectType;
  referenceOrder: DocumentModels.ReferenceCitationsOrderByDocumentsMap;
  relation: Models.LayeredRegularRelationMap<Models.TextRelationStyles>;
};

// IN-PROGRESS: some props can be removed after refactoring of useUndo hook
type DraftjsEditorHook = TextEditorHook & {
  editorRef: DraftjsHook['editorRef'];
  editorState: Draft.EditorState;
  operations: Models.DraftEditorOperation[];
  activeFontFamily: Models.FontFamily;
  setEditorState: DraftjsEditorStateSetter;
  setOperations: (operations: Models.DraftEditorOperations) => void;
  clearOperations: () => void;
  addOperation: (operation: Models.DraftEditorOperation) => void;
  setActiveFontFamily: (value: Models.FontFamily) => void;
  setEditorStateAndOperations: (state: Draft.EditorState) => void;
  onEditorChange: (state: Draft.EditorState) => void;
};

export default function useEditorDraftjs(
  options: HookProps,
  brandProps: BrandProps,
  stylesHook: ReturnType<typeof useStyles>,
  onBeforeChange?: () => void,
): DraftjsEditorHook {
  const prevOptionsRef = useRef(options);
  const prevBrandPropsRef = useRef(brandProps);

  const brandStyle = stylesHook.styles.brandStyle;
  const setBrandStyleChanged = stylesHook.stylesSetters.brandStyleChanged;

  const textAbbreviations = useSelector(selectAbbreviationsData);

  // apply brandStyle and brandProps (initializing)
  const draftjsStateHook = useDraftjsEditorState(
    () => getInitialEditorState(options, stylesHook.styles, brandProps),
    draftjsFeatures.includes(Features.DECORATORS),
  );

  const draftjs = useDraftjsEditor(
    draftjsStateHook,
    {
      editMode: options.editMode,
      projectType: options.projectType,
      colors: brandProps.colors,
      fonts: brandProps.fonts,
      brandStyle,
      abbreviationsData: textAbbreviations,
    },
    draftjsFeatures,
  );

  const {
    editorState, setEditorState, setEditorStateAndAddOperations,
    operations, setOperations, addOperation, addOperationsFromEditorState,
    activeFontFamily, setActiveFontFamily,
  } = draftjsStateHook;
  const { onEditorChange, getEditorState } = draftjs;
  const currentContent = editorState.getCurrentContent();

  const operationsRef = useRef(operations);
  operationsRef.current = operations;

  const optionsRef = useRef(options);
  optionsRef.current = options;

  const resetEditorStateAndOperations = useCallback<DraftjsEditorStateSetter>(
    arg => setEditorStateAndAddOperations(flowWithApplyDraftjsEditorDecorator(arg)),
    [setEditorStateAndAddOperations],
  );

  const clearOperations = useCallback(() => setOperations([]), [setOperations]);

  const onEditorChangeModified = useCallback((state: Draft.EditorState): void => {
    addOperationsFromEditorState(state);
    onEditorChange(state);
  }, [onEditorChange, addOperationsFromEditorState]);

  const { setters, props } = draftjs;
  const modifiedProps = useMemo(() => ({
    ...props,
    fontFamily: activeFontFamily,
  }), [props, activeFontFamily]);

  const modifiedSetters = useMemo<TextEditorSetters>(() => ({
    ...setters,
    abbreviationId: (value): void => {
      setters.abbreviationId(value, onBeforeChange);
      addOperation({ type: 'apply-entity' } as unknown as Models.DraftEditorOperation);
    },
    blockLineHeight: (value): void => {
      setters.blockLineHeight(value);
      setBrandStyleChanged();
    },
    blockType: (value): void => {
      setters.blockType(value);
      setBrandStyleChanged();
    },
    bulletColor: (brandColor: Models.BrandColorMap): void => {
      setters.bulletColor(brandColor);
      setBrandStyleChanged();
      addOperation({ type: Constants.EditorChangeType.SET_BULLET_COLOR });
    },
    fontColor: (brandColor): void => {
      setters.fontColor(brandColor);
      setBrandStyleChanged();
      addOperation({ type: Constants.DraftEditorStateChangeType.CHANGE_INLINE_STYLE });
    },
    fontFamily: (font: Models.BrandFontMap, characterStyle: Models.CharacterStyleMap): void => {
      setters.fontFamily(font, characterStyle);
      setBrandStyleChanged();
    },
    inlineStyle: (style: string): void => {
      setters.inlineStyle(style);
      addOperation({ type: Constants.DraftEditorStateChangeType.CHANGE_INLINE_STYLE });
    },
    scriptStyle: (style: Constants.ScriptType): void => {
      setters.scriptStyle(style);
      addOperation({ type: Constants.DraftEditorStateChangeType.CHANGE_INLINE_STYLE });
    },
    fontSize: (size: number): void => {
      const { projectType } = optionsRef.current;
      const { allowCustomRangeFontSizeSelection } = Constants.ProjectsConfig[projectType];
      if (allowCustomRangeFontSizeSelection) {
        setters.fontSize(size);
      } else {
        setEditorState((prevState) => {
          const selection = editorUtils.selectBlocks(prevState);
          prevState = Draft.EditorState.acceptSelection(
            prevState,
            selection.merge({ hasFocus: true }) as Draft.SelectionState,
          );

          return editorUtils.toggleFontSize(prevState, size);
        });
      }
      setBrandStyleChanged();
    },
    link: value => setters.link(value, onBeforeChange),
  }), [
    setters, setEditorState, addOperation,
    setBrandStyleChanged, onBeforeChange,
  ]);

  const textContent = editorState.getCurrentContent().getPlainText().trim();
  const hasTextContent = Boolean(textContent.length);
  const hasCustomToken = editorUtils.hasToken(textContent);

  // apply brandStyle and brandProps (for setBrandStyle)
  const applyBrandStyleValues = useCallback((brandStyleValues: Models.TextBrandStyles) => {
    resetEditorStateAndOperations((prevState) => {
      const newEditorState = editorUtils.toggleBrandStyle(
        prevState,
        brandStyleValues,
        brandProps.colors,
        brandProps.fonts,
      );

      return applyEditorStateFontStylesForBrandStyles(
        newEditorState,
        optionsRef.current.projectType,
        brandProps.fonts,
      );
    });
  }, [resetEditorStateAndOperations, brandProps]);

  // CELL ACTION EXECUTORS

  const setCursorOnAbbreviation = (abbreviationId: string, abbreviationNumber: number | undefined): boolean => {
    const newState = editorUtils.selectAbbreviation(
      editorState,
      abbreviationId,
      abbreviationNumber,
    );
    if (!newState) {
      return false;
    }
    setEditorState(newState);

    return true;
  };

  const applySelection = (blockKey: string, start: number, end: number): void => {
    setEditorState(editorUtils.applySelection(editorState, blockKey, start, end));
  };

  // IN-PROGRESS: refactoring is needed - use separate hook?
  useEffect(() => {
    const { current: prevOptions } = prevOptionsRef;
    const referencesOrder = getReferenceNumberOrder(
      getPrioritizedRelation(options),
      options.referenceOrder,
    );
    const prevReferencesOrder = getReferenceNumberOrder(
      getPrioritizedRelation(prevOptions),
      prevOptions.referenceOrder,
    );
    const referenceOrderChanged = referencesOrder !== prevReferencesOrder
        && referencesOrder.some((ref, idx = 0) => ref !== prevReferencesOrder.get(idx));

    // IN-PROGRESS: should be refactored
    if (options.document !== prevOptions.document || referenceOrderChanged || prevOptions.editMode !== options.editMode) {
      // apply brandStyle and brandProps (document or relation changed)
      let { editorState: newState } = getInitialEditorState(options, stylesHook.styles, brandProps);
      newState = editorUtils.setFullSelection(newState);
      resetEditorStateAndOperations(newState);
    }
  }, [options.editMode, options.document, options.relation, options.referenceOrder]);

  const flushDataToSave = useCallback(() => {
    const state = getEditorState();
    const text = stateToHTML(
      state.getCurrentContent(),
      editorUtils.getTextStateToHTMLOptions(brandProps.colors, brandProps.fonts),
    );
    const rawContent = JSON.stringify(
      Draft.convertToRaw(removeReferenceCitationsOrder(state)),
    );
    setOperations([]);

    return { text, rawContent, operations: operationsRef.current };
  }, [getEditorState, setOperations, brandProps]);

  // apply brandStyle and brandProps (for list only)
  // IN-PROGRESS: used to apply bullet color to list items and seems deprecated for lexical
  useEffect(() => {
    setColorForListBullets(
      draftjs.editorRef.current,
      editorState,
      brandStyle,
      brandProps.colors,
    );
  }, [currentContent]);

  const { colors, fonts } = brandProps;

  useEffect(() => {
    let newFontFamily = editorUtils.getActiveFontFamily(editorState);

    // don't need to show applied font family if there is no such font in Brand Definition
    // show default font instead in this case
    if (fonts && !fonts.some(font => font?.get('name') === newFontFamily.fontFamily)) {
      newFontFamily = {
        fontFamily: Constants.DefaultCustomStyle.FONT_FAMILY as string,
        characterStyle: undefined,
      };
    }

    if (!_.isEqual(activeFontFamily, newFontFamily)) {
      setActiveFontFamily(newFontFamily);
    }
  }, [editorState]);

  useEffect(() => {
    const { colors: prevColors, fonts: prevFonts } = prevBrandPropsRef.current;
    // check whether we really need setFullSelection or the problem was in other place and was already fixed
    // HACK to trigger Draft Editor re-render when new color styles was provided
    if ((!colors.equals(prevColors) || !fonts.equals(prevFonts))) {
      // apply brandStyle and brandProps (colors, fonts, projtectType)
      let { editorState: newState } = getInitialEditorState(options, stylesHook.styles, brandProps);
      newState = editorUtils.setFullSelection(newState);
      resetEditorStateAndOperations(newState);
    }
  }, [colors, fonts, options.projectType]);

  useEffect(() => {
    prevOptionsRef.current = options;
    prevBrandPropsRef.current = brandProps;
  });

  return {
    // TextEditorHook --------------
    hasChanges: operations.length !== 0,
    hasTextContent,
    hasCustomToken,
    props: modifiedProps,
    setters: modifiedSetters,
    applyBrandStyleValues,
    returnFocusToEditor: draftjs.returnFocusToEditor,
    flushDataToSave,
    // TextEditorHook - CELL ACTION EXECUTORS
    setCursorOnAbbreviation,
    applySelection,
    // custom --------------
    editorRef: draftjs.editorRef,
    editorState,
    operations,
    activeFontFamily,
    setEditorState,
    setOperations,
    clearOperations,
    addOperation,
    setActiveFontFamily,
    setEditorStateAndOperations: resetEditorStateAndOperations,
    onEditorChange: onEditorChangeModified,
  };
}
