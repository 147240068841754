import { $getSelectionStyleValueForProperty, $patchStyleText, getCSSFromStyleObject, getStyleObjectFromCSS } from '@lexical/selection';
import { $getRoot, $getSelection, $isRangeSelection, BaseSelection } from 'lexical';
import * as BrandDefinition from 'modules/BrandDefinition';
import * as Constants from 'const';
import * as Models from 'models';
import * as editorUtils from 'utils/editor';
import { fromPx, toPx } from 'utils/toPx';
import { FontPluginStyle } from './style';

export function brandColorToStyle(value: BrandDefinition.BrandColorLike | undefined): Record<string, string | null> {
  return {
    [FontPluginStyle.BRAND_COLOR_HEX]: value?.HEX ?? null,
    [FontPluginStyle.BRAND_COLOR_NAME]: value?.name ?? null,
    [FontPluginStyle.BRAND_COLOR_TINT]: value?.tint === undefined ? null : value.tint.toString(),
  };
}

export function brandColorFromStyle(
  style: Record<string, string | undefined>,
): BrandDefinition.BrandColorLike | undefined {
  const {
    [FontPluginStyle.BRAND_COLOR_HEX]: HEX,
    [FontPluginStyle.BRAND_COLOR_NAME]: name,
    [FontPluginStyle.BRAND_COLOR_TINT]: tint,
  } = style;
  if (!HEX) {
    return undefined;
  }

  return { HEX, name, tint: tint ? Number(tint) : undefined };
}

export function $getBrandColor(colors: Models.BrandColorsList): Models.BrandColorMap | undefined {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    return undefined;
  }

  const HEX = $getSelectionStyleValueForProperty(selection, FontPluginStyle.BRAND_COLOR_HEX) || undefined;
  const name = $getSelectionStyleValueForProperty(selection, FontPluginStyle.BRAND_COLOR_NAME) || undefined;
  const tint = $getSelectionStyleValueForProperty(selection, FontPluginStyle.BRAND_COLOR_TINT) || undefined;

  if (!HEX) {
    return undefined;
  }

  const color: BrandDefinition.BrandColorLike = { HEX, name, tint: tint ? Number(tint) : undefined };

  return editorUtils.getBrandColor(
    colors,
    color.name ?? color.HEX,
    color.tint,
  );
}

export function $getBrandFont(): Models.FontFamily | undefined {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    return undefined;
  }

  const fontFamily = $getSelectionStyleValueForProperty(selection, FontPluginStyle.BRAND_FONT_NAME) || Constants.DefaultCustomStyle.FONT_FAMILY;
  const characterStyleName = $getSelectionStyleValueForProperty(selection, FontPluginStyle.CHARACTER_STYLE_NAME) || undefined;

  return { fontFamily, characterStyleName };
}

export function $getFontSize(): number | undefined {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    return undefined;
  }
  const value = $getSelectionStyleValueForProperty(selection, FontPluginStyle.FONT_SIZE);

  return value ? fromPx(value) : Constants.DefaultCustomStyle.FONT_SIZE;
}

export function $getInlineStyle(): Constants.InlineStyle[] {
  const selection = $getSelection();
  if (!$isRangeSelection(selection)) {
    return [];
  }
  const result: Constants.InlineStyle[] = [];

  const fontStyle = $getSelectionStyleValueForProperty(selection, FontPluginStyle.FONT_STYLE);
  if (fontStyle === 'italic') {
    result.push(Constants.InlineStyle.ITALIC);
  }

  const fontWeight = $getSelectionStyleValueForProperty(selection, FontPluginStyle.FONT_WEIGHT);
  if (fontWeight === 'bold' || Number(fontWeight) >= 700) {
    result.push(Constants.InlineStyle.BOLD);
  }

  if (selection.hasFormat('underline')) {
    result.push(Constants.InlineStyle.UNDERLINE);
  }

  return result;
}

export function $updateBrandStyleAndColors(
  brandStyle: Models.BrandStyleMap | undefined, // IN-PROGRESS: should we take in account default colors?
  colors: Models.BrandColorsList,
): void {
  const root = $getRoot();
  root.getAllTextNodes().forEach((node) => {
    const style = getStyleObjectFromCSS(node.getStyle());
    const color = brandColorFromStyle(style);
    if (!color) {
      // IN-PROGRESS: according to the current logic brandStyle will no effect default color of the text
      return;
    }
    const newValue = BrandDefinition.getCSSColorFromBrandColor(color, colors);
    if (style[FontPluginStyle.COLOR] === newValue) {
      return;
    }
    node.setStyle(getCSSFromStyleObject({
      ...style,
      [FontPluginStyle.COLOR]: newValue,
    }));
  });
}

export function $patchFontWeight(selection: BaseSelection, isBold: boolean): void {
  $patchStyleText(selection, {
    [FontPluginStyle.FONT_WEIGHT]: isBold ? '700' : '400',
  });
}

export function $patchFontStyle(selection: BaseSelection, isNormal: boolean): void {
  $patchStyleText(selection, {
    [FontPluginStyle.FONT_STYLE]: isNormal ? 'normal' : 'italic',
  });
}

export function $patchFontSize(selection: BaseSelection, size: number): void {
  $patchStyleText(selection, {
    [FontPluginStyle.FONT_SIZE]: toPx(size) ?? null,
  });
}
