import Draft from 'draft-js';
import { LexicalEditor, SerializedEditorState, SerializedLexicalNode } from 'lexical';
import { useEffect, useMemo, useRef } from 'react';
import * as Models from 'models';
import { lexicalRawConverter } from '../LexicalRawConverter/LexicalRawConverter';

const EMPTY_EDITOR_STATE: SerializedEditorState<SerializedLexicalNode> = {
  root: {
    children: [{ type: 'paragraph', version: 1 }],
    direction: null,
    format: '',
    indent: 0,
    type: 'root',
    version: 1,
  },
};

type Props = {
  editor: LexicalEditor | null;
  draftjsContent?: Draft.ContentState;
  brandProps: {
    colors: Models.BrandColorsList;
    fonts: Models.BrandFontsList;
  };
  lexicalStateStringified?: string;
};

export function useLexicalEditorState(props: Props): null | string {
  const { editor, draftjsContent, brandProps: { colors, fonts }, lexicalStateStringified } = props;

  const editorState = useMemo(() => {
    if (lexicalStateStringified) {
      return lexicalStateStringified;
    }
    if (!draftjsContent) {
      return JSON.stringify(EMPTY_EDITOR_STATE);
    }
    const rawContent = Draft.convertToRaw(draftjsContent);
    const formatedData = lexicalRawConverter(rawContent, colors, fonts);

    return JSON.stringify(formatedData.editorState);
  }, [draftjsContent, colors, fonts, lexicalStateStringified]);

  const initialEditorStateRef = useRef(editorState);

  useEffect(() => {
    if (initialEditorStateRef.current === editorState) {
      return;
    }
    if (!editor) {
      return;
    }
    editor.setEditorState(editor.parseEditorState(editorState));
  }, [editor, editorState]);

  return initialEditorStateRef.current;
}
