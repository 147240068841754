import { $getSelectionStyleValueForProperty } from '@lexical/selection';
import { $getNearestBlockElementAncestorOrThrow } from '@lexical/utils';
import {
  $getSelection,
  $isRangeSelection,
  $isTextNode,
  LexicalNode,
  PointType,
} from 'lexical';
import * as Constants from 'const';
import { NO_WRAP_VALUE, WHITE_SPACE_PROP } from './style';
import { BaseProps } from './types';

function $getScriptStyleFromSelection(): BaseProps['scriptStyle'] {
  const selection = $getSelection();

  if (!$isRangeSelection(selection)) {
    return undefined;
  }

  if (selection.hasFormat('superscript')) {
    return Constants.ScriptType.SUPERSCRIPT;
  }

  if (selection.hasFormat('subscript')) {
    return Constants.ScriptType.SUBSCRIPT;
  }

  return undefined;
}

function $getTextNoWrapFromSelection(): BaseProps['textNoWrap'] {
  const selection = $getSelection();
  if (selection && $isRangeSelection(selection)) {
    return $getSelectionStyleValueForProperty(selection, WHITE_SPACE_PROP) === NO_WRAP_VALUE;
  }

  return false;
}

export function $getBaseProps(): BaseProps {
  return {
    scriptStyle: $getScriptStyleFromSelection(),
    textNoWrap: $getTextNoWrapFromSelection(),
  };
}

export function $clearTextNodesFormatting(
  nodes: LexicalNode[],
  extractedNodes: LexicalNode[],
  anchor: PointType,
  focus: PointType,
): void {
  nodes.forEach((node, idx) => {
    if ($isTextNode(node)) {
      let textNode = node;
      if (idx === 0 && anchor.offset !== 0) {
        textNode = textNode.splitText(anchor.offset)[1] || textNode;
      }
      if (idx === nodes.length - 1) {
        textNode = textNode.splitText(focus.offset)[0] || textNode;
      }

      if (nodes.length === 1 && $isTextNode(extractedNodes[0])) {
        textNode = extractedNodes[0];
      }

      if (textNode.__style !== '') {
        textNode.setStyle('');
      }
      if (textNode.__format !== 0) {
        textNode.setFormat(0);
        $getNearestBlockElementAncestorOrThrow(textNode).setFormat('');
      }
    }
  });
}
