import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import Immutable from 'immutable';

import { useEffect } from 'react';
import * as Models from 'models';
import { HISTORY_TAGS } from '../HistoryWithExtraStatePlugin';
import { $verifyListStyles, $updateListBrandStyle } from './brandStyle';
import { registerCommands } from './commands';
import { $getBulletColorForFirstList, $getSelectionForListItem } from './utils';

export type CustomListOnChangeProps = {
  color: Models.BrandColorMap | undefined;
};

type Props = {
  brandStyle: Models.BrandStyleMap | undefined;
  brandStyleChanged: boolean | undefined;
  onChange: (values: CustomListOnChangeProps | false) => void;
  colors: Models.BrandColorsList;
};

export function CustomListPlugin(props: Props): null {
  const { onChange, colors, brandStyle, brandStyleChanged } = props;
  const [editor] = useLexicalComposerContext();

  useEffect(
    () => registerCommands(editor),
    [editor],
  );

  useEffect(() => {
    editor.update(() => {
      $updateListBrandStyle(brandStyle, brandStyleChanged, colors);
    }, { tag: HISTORY_TAGS.MERGE, discrete: true });
  }, [editor, brandStyle, brandStyleChanged]);

  useEffect(() => {
    editor.update(() => {
      $verifyListStyles(colors);
    }, { tag: HISTORY_TAGS.MERGE, discrete: true });
  }, [editor, colors]);

  useEffect(() => {
    const readEditorStateAndNotifyChange = (): void => {
      const selection = $getSelectionForListItem();
      if (!selection) {
        onChange(false);

        return;
      }

      const color = $getBulletColorForFirstList(colors);
      onChange({
        color: color ? Immutable.Map(color) as unknown as Models.BrandColorMap : undefined,
      });
    };
    editor.getEditorState().read(readEditorStateAndNotifyChange);

    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(readEditorStateAndNotifyChange);
    });
  }, [editor, onChange, colors]);

  return null;
}
