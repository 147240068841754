import { OrderedSet } from 'immutable';
import { LexicalEditor } from 'lexical';
import { usePropsUpdater } from 'modules/Lexical/hooks/usePropsUpdater';
import { LexicalAbbreviationValues } from 'modules/Lexical/Plugins/AbbreviationPlugin';
import { ABBREVIATION_CHANGE } from 'modules/Lexical/Plugins/AbbreviationPlugin/commands';
import { $selectAbbreviation } from 'modules/Lexical/Plugins/AbbreviationPlugin/utils';
import { BASE_PROPS_COMMAND, BaseProps } from 'modules/Lexical/Plugins/BasePropsPlugin';
import { CustomListOnChangeProps } from 'modules/Lexical/Plugins/CustomListPlugin';
import { BULLET_COLOR_COMMAND } from 'modules/Lexical/Plugins/CustomListPlugin/commands';
import { FONT_COMMAND, FontProps } from 'modules/Lexical/Plugins/FontPlugin';
import { LINK_COMMAND, LinkProps } from 'modules/Lexical/Plugins/LinkNodePlugin';
import { TEXT_BLOCK_COMMAND, TextBlockProps } from 'modules/Lexical/Plugins/TextBlockPlugin';
import { RefObject, useCallback, useMemo, useRef, useState } from 'react';
import * as Constants from 'const';
import * as Models from 'models';
import * as editorUtils from 'utils/editor';
import { TextEditorHook, TextEditorSetters, TextEditorProps } from './TextEditorHook';
import useStyles from './useStyles';

export type LexicalOnChange = {
  abbreviations: (values: LexicalAbbreviationValues) => void;
  baseProps: (values: BaseProps) => void;
  textBlock: (values: TextBlockProps) => void;
  bulletToggle: (values: CustomListOnChangeProps) => void;
  font: (values: FontProps) => void;
  link: (values: LinkProps) => void;
  textContent: (value: string) => void;
};

type LexicalTextEditorHook = TextEditorHook & {
  ref: RefObject<LexicalEditor>;
  onChange: LexicalOnChange;
};

type Props = {
  stylesHook: ReturnType<typeof useStyles>;
};

export function useLexicalTextEditor(options: Props): LexicalTextEditorHook {

  const { stylesHook } = options;
  const setBrandStyleChanged = stylesHook.stylesSetters.brandStyleChanged;

  const ref = useRef<LexicalEditor>(null);
  const editor = ref.current;

  const { props, updateProps } = usePropsUpdater<TextEditorProps>({
    abbreviationId: undefined,
    abbreviationTerm: undefined,
    blockLineHeight: undefined,
    blockType: undefined,
    bulletColor: undefined,
    fontColor: undefined,
    fontFamily: undefined,
    fontSize: undefined,
    inlineStyle: OrderedSet<Constants.InlineStyle>([]),
    link: undefined,
    linkApplicable: false,
    scriptStyle: undefined,
    textNoWrap: false,
  });

  // IN-PROGRESS: get initial value from props
  const [textContent, setTextContent] = useState('');

  const setters = useMemo((): TextEditorSetters => ({
    abbreviationId: (id: string | undefined) => editor?.dispatchCommand(ABBREVIATION_CHANGE, id),
    blockLineHeight: (value): void => {
      if (!editor) {
        return;
      }
      setBrandStyleChanged(true);
      editor.dispatchCommand(TEXT_BLOCK_COMMAND.BLOCK_LINE_HEIGHT, value);
    },
    blockType: (type): void => {
      if (!editor) {
        return;
      }
      setBrandStyleChanged(true);
      editor?.dispatchCommand(TEXT_BLOCK_COMMAND.BLOCK_TYPE, type);
    },
    bulletColor: (color: Models.BrandColorMap): void => {
      setBrandStyleChanged(true);
      editor?.dispatchCommand(BULLET_COLOR_COMMAND, color);
    },
    fontColor: brandColor => editor?.dispatchCommand(FONT_COMMAND.BRAND_COLOR, brandColor),
    fontFamily: (
      brandFont: Models.BrandFontMap,
      characterStyle: Models.CharacterStyleMap,
    ) => editor?.dispatchCommand(FONT_COMMAND.BRAND_FONT, { brandFont, characterStyle }),
    fontSize: (value: number) => editor?.dispatchCommand(FONT_COMMAND.SIZE, value),
    inlineStyle: (style: Constants.InlineStyle) => editor?.dispatchCommand(
      FONT_COMMAND.INLINE_STYLE,
      style,
    ),
    link: (value: string | undefined) => editor?.dispatchCommand(LINK_COMMAND, value),
    scriptStyle: (value: Constants.ScriptType) => editor?.dispatchCommand(BASE_PROPS_COMMAND.SCRIPT_STYLE, value),
    textNoWrap: () => editor?.dispatchCommand(BASE_PROPS_COMMAND.TEXT_NOWRAP, undefined),
  }), [editor]);

  const onChange: LexicalOnChange = useMemo(() => ({
    abbreviations: values => updateProps({
      abbreviationId: values.abbreviationId,
      abbreviationTerm: values.abbreviationTerm,
    }),
    baseProps: (values: BaseProps) => updateProps({
      scriptStyle: values.scriptStyle,
      textNoWrap: values.textNoWrap,
    }),
    textBlock: (values: TextBlockProps) => updateProps(prev => ({
      blockLineHeight: values.blockLineHeight,
      blockType:  values.blockType ?? (prev.blockType ?? Constants.TextHorizontalAlignmentType.LEFT),
    })),
    bulletToggle: values => updateProps(prev => ({
      blockType: values
        ? Constants.TextHorizontalAlignmentType.UNORDERED_LIST
        : (prev.blockType ?? Constants.TextHorizontalAlignmentType.LEFT),
      bulletColor: values?.color,
    })),
    font: values => updateProps({
      fontColor: values.brandColor,
      fontFamily: values.brandFont,
      fontSize: values.size,
      inlineStyle: values.inlineStyle,
    }),
    link: values => updateProps({
      link: values.link,
      linkApplicable: values.linkApplicable,
    }),
    textContent: value => setTextContent(value),
  }), [updateProps]);

  const applyBrandStyleValues = useCallback(() => {}, []);

  const returnFocusToEditor = useCallback(() => {
    // https://github.com/facebook/lexical/issues/4460
    ref.current?._rootElement?.focus();
  }, []);

  // CELL ACTION EXECUTORS

  const setCursorOnAbbreviation = (abbreviationId: string, abbreviationNumber: number | undefined): boolean => {
    let results = false;
    ref.current?.update(() => {
      results = $selectAbbreviation(abbreviationId, abbreviationNumber);
    }, { discrete: true });

    return results;
  };

  const applySelection = (): void => {
    // lexical abbreviation works without this action executor
    // so we decide to skip its implementation
  };

  const flushDataToSave = useCallback(() => {
    const rawContent = JSON.stringify(editor?.getEditorState());

    return { text: '', rawContent, operations: [] };
  }, [editor]);

  return {
    // TextEditorHook --------------
    hasChanges: true, // IN-PROGRESS should be implemented
    hasTextContent: Boolean(textContent.length),
    hasCustomToken: editorUtils.hasToken(textContent),
    props,
    setters,
    applyBrandStyleValues,
    returnFocusToEditor,
    flushDataToSave,
    // TextEditorHook - CELL ACTION EXECUTORS
    setCursorOnAbbreviation,
    applySelection,
    // custom --------------
    ref,
    onChange,
  };
}
