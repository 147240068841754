import classNames from 'classnames';
import Immutable from 'immutable';
import * as BrandDefinition from 'modules/BrandDefinition';
import React, { useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLayout } from 'components/ArtboardLayout/hooks/useLayout';
import ReferenceCitationEditor from 'components/ReferenceCitationEditor';
import ReferenceCitationPreview from 'components/ReferenceCitationPreview';
import { Layer, ProjectsConfig } from 'const';
import * as ArtboardCellActions from 'containers/ArtboardCell/actions';
import ArtboardLayoutActions from 'containers/ArtboardLayoutActions';
import * as CommonSelectors from 'containers/Common/selectors';
import * as DocumentsActions from 'containers/Documents/actions';
import * as DocumentsSelectors from 'containers/Documents/selectors';
import * as ProjectSelectors from 'containers/Project/selectors';
import * as RelationsActions from 'containers/Relations/actions';
import * as RelationsSelectors from 'containers/Relations/selectors';
import * as ScreenDefinitionsSelectors from 'containers/ScreenDefinitions/selectors';
import { Priority, useClickOutsideWithToggle } from 'hooks/useClickOutside';
import { useUpdateCellsHeight } from 'hooks/useUpdateCellsHeight';
import * as Models from 'models';
import { cleanUpHtml } from 'utils/cleanUpHtml';
import { getExtraHeight } from 'utils/getExtraHeight';
import { getReferencesAsString } from 'utils/getReferencesAsString';
import { intlGet } from 'utils/intlGet';
import { isLayoutScrollable } from 'utils/layouts/isLayoutScrollable';
import {
  getFontFamily,
  getFontSize,
  getReferenceElementAlignment,
} from 'utils/styles';
import { toPx } from 'utils/toPx';
import { ReferenceCitationElementProps } from './models';
import css from './styles.module.scss';
import Toolbar from './Toolbar';

const ReferenceCitationElement: React.FunctionComponent<ReferenceCitationElementProps> = (originalProps) => {
  const dispatch = useDispatch();

  const props = {
    ...originalProps,
    documentIdsOrderBeforeReferenceElement: useSelector(DocumentsSelectors.documentIdsOrderBeforeReferenceElement),
    documents: useSelector(DocumentsSelectors.documents),
    flatColorsByLayoutId: useSelector(CommonSelectors.flatColorsByLayoutId),
    flatFontsByRelationId: useSelector(CommonSelectors.flatFontsByRelationId),
    isOpenToolbar: useSelector(ProjectSelectors.isOpenToolbar),
    projectType: useSelector(ProjectSelectors.projectType),
    referenceCitationsByReferenceElements: useSelector(DocumentsSelectors.referenceCitationsByReferenceElements),
    relationsByRegularLayoutId: useSelector(RelationsSelectors.relationsByRegularLayoutId),
    sectionsWidth: useSelector(ScreenDefinitionsSelectors.sectionsWidthByName),
  };

  const {
    connectDragSource,
    documentIdsOrderBeforeReferenceElement,
    flatFontsByRelationId,
    isOpenToolbar,
    layoutId,
    notEditable,
    projectType,
    referenceCitationsByReferenceElements,
    relationsByRegularLayoutId,
  } = props;
  const {
    container: referenceCitationElement,
    editMode: layoutEditMode,
    enableEditMode: enableLayoutEditMode,
    layout,
    onResize,
    styles,
    stylesSetters,
    stylesCSS,
  } = useLayout(props);
  // RC element stores styles on original layer only (styles are shared between layers)
  const layer = Layer.ORIGINAL;
  const {
    container: referenceCitationEditors,
    on: textEditMode,
    toggleOn: toggleTextEditModeOn,
    toggleOff: toggleTextEditModeOff,
  } = useClickOutsideWithToggle<HTMLDivElement>(Priority.TOOLBAR, {
    closeDropdownCallback: () => { setCurrentReferenceIdState(''); },
  });

  const [currentReferenceId, setCurrentReferenceIdState] = React.useState('');

  const layoutRelations = relationsByRegularLayoutId.get(layout.get('id'));
  const layoutRelationId = layout.get('relationId');
  const layoutRelation = layoutRelations?.get(layoutRelationId) as Models.ColumnRelationMap;
  const rowRelationId = layoutRelation.get('relationIds').first<string>();
  const rowRelation = layoutRelations?.get(rowRelationId) as Models.RowRelationMap;
  const relationId = rowRelation.get('relationIds').first<string>();
  const relation = layoutRelations?.get(relationId) as Models.LayeredRegularRelationMap<Models.ReferenceCitationElementStyles>;

  const documentIdsOrder = documentIdsOrderBeforeReferenceElement.get(relationId);
  const referencesOrder = referenceCitationsByReferenceElements.get(relationId);

  useUpdateCellsHeight(
    relationId,
    referenceCitationElement,
    getExtraHeight(layout.get('styles')),
  );

  React.useEffect(
    () => {
      const isBlank = referencesOrder.size === 0;

      if (textEditMode) {
        if (isBlank) {
          toggleTextEditModeOff();
        } else {
          setCurrentReferenceIdState(referencesOrder.first<Models.ReferenceCitationMap>().get('id'));
        }
      }
    },
    [referencesOrder, textEditMode],
  );

  const setCurrentReferenceId = (id: string): void => {
    // user clicks on ReferenceCitationPreview component and next it will be replaced by ReferenceCitationEditor,
    // therefore ReferenceCitationPreview will be removed from DOM, but we need to check was click outside or not,
    // so for that purpose we set currentReferenceId on next tick to make check above before the element will be removed.
    setTimeout(() => setCurrentReferenceIdState(id));
  };

  const enableTextEditMode: React.MouseEventHandler = () => {
    if (!isOpenToolbar) {
      toggleTextEditModeOn();
    }
  };

  const deleteReferenceFromText = useCallback((referenceId: string): void => {
    dispatch(ArtboardCellActions.deleteReferenceCitationFromComponents(referenceId, documentIdsOrder));
  }, [dispatch, documentIdsOrder]);

  const updateReference = useCallback((id: string, text: string) => {
    dispatch(DocumentsActions.updateReferenceRequest(id, cleanUpHtml(text), documentIdsOrder));
  }, [dispatch, documentIdsOrder]);

  const updateRelations = useCallback((relations: Models.LayeredRelationsMap) => {
    dispatch(RelationsActions.updateLayeredRelations(relations));
  }, [dispatch]);

  const toggleFontSize = (fontSize: number): void => {
    const updatedRelation = relation.setIn(['styles', layer, 'fontSize'], fontSize);

    updateRelations(Immutable.Map({ [updatedRelation.get('id')]: updatedRelation }) as Models.LayeredRegularRelationsMap);
  };

  const getReferenceCitationElementHeight = (): React.CSSProperties => {
    return !notEditable && isLayoutScrollable(layout) && !textEditMode && height
      ? { height: toPx(height) }
      : null;
  };

  const prepareReferencesForEditing = (): JSX.Element => {
    return (
      <div ref={referenceCitationEditors} className={css.wrapper}>
        {
          referencesOrder.map((reference, index) => {
            const id = reference.get('id');

            return currentReferenceId && currentReferenceId === id || !currentReferenceId && index === 0
              ?
              <ReferenceCitationEditor
                key={id}
                reference={reference}
                index={index}
                deleteReferenceFromText={deleteReferenceFromText}
                updateReference={updateReference}
                layoutId={layoutId}
              />
              :
              <ReferenceCitationPreview
                key={id}
                reference={reference}
                index={index}
                setCurrentReferenceId={setCurrentReferenceId}
                deleteReferenceFromText={deleteReferenceFromText}
              />;
          })
        }
      </div>
    );
  };

  const fonts = flatFontsByRelationId.get(relation.get('id'));
  const references = getReferencesAsString(referencesOrder, projectType);
  const isBlank = !references;
  const relationStyles = relation.getIn(['styles', layer]);
  const fontColor = relationStyles.get('fontColor');
  const fontFamily = relationStyles.get('fontFamily');
  const fontStyle = relationStyles.get('fontStyle');
  const fontSize = relationStyles.get('fontSize');
  const lineHeight = relationStyles.get('lineHeight');
  const alignment = relationStyles.get('alignment');
  const isScrollable = isLayoutScrollable(layout);
  const layoutStyles = layout.get('styles') as Models.LayoutStylesMap;
  const height = layoutStyles.get('height');

  return (
    <>
      <div
        className={classNames(css.ReferenceCitationElement, {
          [css.blank]: isBlank,
          [css.editMode]: textEditMode && !isBlank,
          [css.scrollable]: isScrollable && !textEditMode,
          [css.boldNumbers]: ProjectsConfig[projectType].useBoldNumberingInReferences,
        })}
        onDoubleClick={notEditable ? null : enableTextEditMode}
        style={{
          color: fontColor,
          lineHeight,
          ...stylesCSS,
          ...getFontFamily(
            BrandDefinition.getCSSFontFamilyFromBrandFont(fontFamily, fontStyle, fonts),
          ),
          ...getReferenceElementAlignment(alignment),
          ...getFontSize(!isBlank ? fontSize : null),
          ...getReferenceCitationElementHeight(),
        }}
        ref={referenceCitationElement}
      >
        {
          isBlank
            ? intlGet('Artboard.ReferenceCitationElement', 'Blank')
            : textEditMode && !isBlank
              ? prepareReferencesForEditing()
              : <div dangerouslySetInnerHTML={{ __html: references }} />
        }
      </div>
      {
        layoutEditMode &&
        <Toolbar
          styles={styles}
          stylesSetters={stylesSetters}
          projectType={projectType}
          fontSize={fontSize}
          toggleFontSize={toggleFontSize}
          layoutId={layoutId}
        />
      }
      <ArtboardLayoutActions
        connectDragSource={connectDragSource}
        layout={layout}
        height={height}
        enableLayoutEditMode={enableLayoutEditMode}
        editMode={layoutEditMode}
        isResizeAvailable={isScrollable}
        handleDrag={onResize}
      />
    </>
  );
};

export default ReferenceCitationElement;
